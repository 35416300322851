/* eslint-disable */

export const buildEnvironment = __BUILD_ENVIRONMENT__;
export const runEnvironment = __RUN_ENVIRONMENT__;
export const firebaseConfig = __FIREBASE_CONFIG__;
export const shotsBaseUrl = __SHOTS_BASE_URL__;
export const generalShotsBaseUrl = __GENERAL_SHOTS_BASE_URL__;
export const generalShotsBucketUrl = __GENERAL_SHOTS_BUCKET_URL__;
export const postRaceVideosBaseUrl = __POST_RACE_VIDEOS_BASE_URL__;
export const postRaceVideosBucketUrl = __POST_RACE_VIDEOS_BUCKET_URL__;
export const useFirestoreEmulator = __USE_FIRESTORE_EMULATOR__;
export const webpushToken = __WEBPUSH_TOKEN__;
export const domain = __DOMAIN__;
export const sentryDSN = __SENTRY_DSN__;
export const generalBroadcastBeforeEndMs = 1000;
