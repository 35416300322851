{
  "title": "ExperiRace",
  "pages": {
    "home": {
      "title": "Start",
      "welcome": "Hallo Laufbegeisterte!",
      "subtitle": "Erlebe Laufsport-Veranstaltungen, wie nie zuvor mit ExperiRace"
    },
    "login": {
      "title": "Anmeldung",
      "welcome": "Anmeldung bei der App",
      "googleLogin": "Anmeldung mit Google",
      "alreadyLoggedIn": "Willkommen bei ExperiRace!"
    },
    "generalBroadcast": {
      "participantBtnTooltip": "Persönliche Übertragungen für Läufer",
      "followBtn": "Folge deinem Lieblingsläufer"
    },
    "broadcast": {
      "promote": "Wir übertragen diesen Lauf, damit du dabei sein und die Aufregung des Laufes wie nie zuvor erleben kannst.",
      "liveButtonBeforeRace": "Die Live-Übertragung beginnt bald",
      "liveButtonDuringRace": "Sieh dir die Live-Übertragung an",
      "liveButtonAfterRace": "Sieh dir die Wiederholung des Laufes an",
      "goToBroadcast": "Gehe zu Läuferübertragung",
      "broadcast": "Voller Zugang",
      "broadcasted": "Voller Zugang",
      "track": "Upgrade verfügbar",
      "tracked": "Upgrade verfügbar",
      "feedTitle": "Klicke hier zusehen",
      "feedSubtitle": "Die Live-Übertragung",
      "runners": "Registrierte Läufer",
      "tableBeforeRace": "Teilnehmer des Laufes",
      "tableDuringRace": "Fortschritt des Laufes",
      "tableAfterRace": "Ergebnisse des Laufes",
      "finishRaceTimeSecBeforeFinish": "Voraussichtlicher Zieleinlauf",
      "finishRaceTimeSecAfterFinish": "Zieleinlaufzeit",
      "estimatedDistance": "Voraussichtliche Distanz jetzt",
      "lastAppearance": "Zuletzt getrackte Position",
      "sort": "Sortieren",
      "bib": {
        "asc": "Startnummern aufsteigend",
        "desc": "Startnummern absteigend"
      },
      "participant": {
        "asc": "Name A-Z",
        "desc": "Name Z-A"
      },
      "progress": {
        "asc": "Führende",
        "desc": "Schlussläufer"
      }
    },
    "broadcastsParticipant": {
      "noMoment": "Keine Momente für diesen Läufer vorhanden :(",
      "raceNotStartedLabel": "Der Lauf hat noch nicht begonnen!",
      "raceNotStartedTime": "Beginnt am {time}",
      "raceStartedNoMomentLabel": "Der Lauf hat begonnen",
      "raceStartedNoMomentSoon": "Bleib dran für das Startvideo...",
      "raceFinishedNoRunnerLabel": "Der Lauf ist offiziell beendet!",
      "nextCameraPointWithEta": "Wir erwarten, dass der Läufer {distance} um {eta} erreicht",
      "nextCameraPointAnyTime": "Wir erwarten, dass der Läufer {distance} jederzeit erreicht",
      "finishTitle": "{0} hat den Lauf beendet",
      "finishTime1": "Dauer des Laufes {0}",
      "finishTime2": ", was bedeutet {0}",
      "finishTimeFasterToGoal": " schneller als geplant!",
      "finishTimeSlowerToGoal": " langsamer als geplant!",
      "finishTimeFasterToAvg": " schneller als der Durchschnitt!",
      "finishTimeSlowerToAvg": " langsamer als der Durchschnitt!",
      "calendarEventTitle": "Die ExperiRace von {name}",
      "calendarEventDescription": "Folge der Übertragung von {name}!\n\nURL: {url}",
      "feedback": "Bitte klicke {button}, um diesen Service zu verbessern.",
      "feedbackButton": "Gib uns dein Feedback",
      "getUpdatesClickHere": "Abonniere Updates",
      "getUpdatesBarTooltip": "Abonnieren, um Update-Benachrichtigungen zu diesem Läufer zu erhalten.",
      "unsubscribeClickHere": "Updates abbestellen",
      "unsubscribeBarTooltip": "Update-Benachrichtigungen für diesen Läufer abbestellen",
      "subscribeTitle": "Update-Benachrichtigungen abonnieren",
      "subscribeInitialMessage": "Akzeptiere die Benachrichtigungen in dem Browser-Popup, das sich gerade geöffnet hat, damit du benachrichtigt wirst, wenn wir ein Updates zu diesem Läufer haben.",
      "subscribeSuccessfulTitle": "Update-Benachrichtigungen erfolgreich abonniert",
      "subscribeProgressMessage": "Updates für {name}  abonnieren ...",
      "unsubscribeTitle": "Benachrichtigungen abmelden",
      "unsubscribeProgressMessage": "Abmeldung von Updates zu {name} ...",
      "unsubscribeConfirm": "Bist du sicher, dass du dich von den Updates zu {name} abmelden möchtest?",
      "orderPostRaceVideo": "Post-Event-Video kaufen",
      "orderParticipantBroadcast": "Läuferübertragung freischalten",
      "goToPostRaceVideo": "Post-Event-Video ansehen",
      "lapN": "Runde {0}",
      "previewMoment": {
        "bannerTitle": "Läuferübertragung {0}",
        "bannerTitlePreview": "Vorschau",
        "title": "Freischalten der Videos zu {0}",
        "preview": "Dies ist nur eine Vorschau. ",
        "descriptionLine1": "Wenn du die Videos in {quality} sehen möchtest, musst du diese Läuferübertragung {purchase}.  Der Kauf beinhaltet außerdem ein exklusives {postrace}!",
        "quality": "Originalqualität und Originalauflösung",
        "purchase": "kaufen",
        "postrace": "exklusives Video der Highlights nach dem Lauf",
        "descriptionLine2": "{0}, um mehr darüber zu erfahren.",
        "clickhere": "Hier klicken",
        "order": "Freischalten"
      }
    },
    "broadcastsParticipantsPostrace": {
      "title": "Video nach dem Lauf",
      "smallTitle": "Schau dir meinen Lauf auf {0} an",
      "like": "Bitte teile meinen Lauf, wenn er dir gefallen hat!"
    },
    "translationTest": {
      "title": "Titel testen"
    },
    "admin": {
      "generalShots": {
        "deleteTitle": "Allgemeine Aufnahme löschen",
        "deleteMessage": "Möchtest du diese allgemeine Aufnahme löschen?",
        "noRepeatStart": "Nicht wiederholen für",
        "noRepeatEnd": "Minuten",
        "participants": "Interviewteilnehmer",
        "generalDescription": "Atmosphärische Aufnahme, die einmal in einer Übertragung gespielt wird",
        "beforeAfterStartDescription": "Aufnahme, die wiederholt gespielt wird, falls keine andere Aufnahme vorhanden ist",
        "beforeStart": "vor dem Start",
        "afterStart": "nach dem Start",
        "cameraPointIntro": "Intro des Kamerastandorts",
        "cameraPointIntroOn": "auf",
        "cameraPointIntroTrack": "Strecke",
        "missingCameraPoint": "Fehlender Kamerastandort ",
        "missingTrack": "Fehlende Strecke",
        "missingInterviewee": "Fehlender Interviewpartner",
        "interviewDescription": "Das Interview wird einmal in einer Übertragung gezeigt mit",
        "repeatableDescription": "Eine atmosphärische Aufnahme, zur Wiederholung alle",
        "repeatableDescriptionEnd": "Minuten",
        "addNewShot": "Neue Aufnahme hochladen",
        "preparingShot": "Aufnahme wird vorbereitet...",
        "text": "Text zur Aufnahme hinzufügen"
      },
      "cameraPoints": {
        "pageTitle": "Kamerapunkt",
        "pageSubtitle": "Einrichten & Überwachen",
        "selectTracks": "Strecke(n) auswählen",
        "zoomToTracks": "Auf Strecken zoomen",
        "showReceptionMetrics": "Metriken zum Netzwerkempfang anzeigen",
        "showMoments": "Momente anzeigen",
        "showMomentCircles": "Radius der Momente anzeigen",
        "changeLayers": "Ebene wechseln",
        "startPoints": "Start der Strecke(n)",
        "finishPoints": "Ziel der Strecke(n)",
        "name": "Name",
        "rank": "Platzierung",
        "coordinates": "Koordinaten",
        "latitude": "Breitengrad",
        "longitude": "Längengrad",
        "altitude": "Höhenlage",
        "track": "Strecke",
        "positionOnTracks": "Position auf der/den Strecke(n)",
        "claimedBy": "Erreicht von",
        "lastClaimBy": "Erreicht von",
        "isStart": "Start",
        "isFinish": "Ziel",
        "distance": "Distanz",
        "elevationGain": "Höhendifferenz",
        "plannedTime": "Geplante Zeit",
        "tracks": "Strecken",
        "averageUploadSpeed": "Durchschnittliche Upload-Geschwindigkeit",
        "networkSpeedMeasurement": "Netzwerkgeschwindigkeit",
        "videoLengthUploadDurationRatio": "Videolänge zur Uploadzeit",
        "videoLengthUploadDurationRealtime": "Upload in Echtzeit",
        "videoLengthUploadDurationSlow": "Kein Echtzeit-Upload",
        "moment": "Video",
        "momentId": "Moment ID",
        "shotId": "Shot ID",
        "trackId": "Track ID",
        "recordingFinishedAt": "Aufnahme beendet um",
        "uploadStartedAt": "Upload gestartet um",
        "cameraPointOwnerName": "Kamerapunktbesitzer",
        "title": {
          "cameraPoints": "Kamerapunkte",
          "videographers": "Videofilmer"
        },
        "videographers": {
          "cameraPointNames": "Kamera(s)",
          "name": "Name",
          "deviceName": "Gerät",
          "battery": "Batterie",
          "queueSize": "Video-Warteschlange",
          "diskSize": "Freier Speicherplatz",
          "lastCameraPointLoginAt": "Letzte Anmeldung bei Kamera"
        }
      }
    },
    "permissionDenied": {
      "title": "Berechtigung verweigert",
      "message": "Du hast keine Berechtigung, auf diese Seite zuzugreifen"
    }
  },
  "broadcast": {
    "raceStartSoon": "Der Lauf startet demnächst",
    "startCameraPoint": "Start",
    "finishCameraPoint": "Ziel"
  },
  "notifications": {
    "blockedMessage": "Es sieht so aus, als hättest du zuvor die Erlaubnis verweigert, Benachrichtigungen von dieser Webseite anzuzeigen.",
    "blockedToEnable": "Um Update-Benachrichtigungen für deinen Läufer zu erhalten, erlaube bitte Benachrichtigungen:",
    "blockedLastStep": "Möglicherweise ist es notwendig, die Seite neu zu laden und erneut zu abonnieren",
    "blockedChrome": {
      "step1": "Klicke auf das Schloss-Symbol in der Adressleiste deines Browsers.",
      "step2": "Benachrichtigungen zulassen"
    },
    "blockedSafari": {
      "step1": "Öffne die Einstellungen deines Browsers",
      "step2": "Gehe zu den Webseiteneinstellungen",
      "step3": "Wähle Benachrichtigungen und erlaube dann die Benachrichtigungen für die Webseite app.experirace.com"
    },
    "blockedEdge": {
      "step1": "Klicke auf das Schloss-Symbol in der Adressleiste deines Browsers",
      "step2": "Benachrichtigungen zulassen"
    },
    "blockedAndroid": {
      "step1": "Tippe auf das Glocken- oder Seiteneinstellungssymbol rechts neben der Adressleiste deines Browsers",
      "step2": "Tippe auf Benachrichtigungen",
      "step3": "Erlaube die Benachrichtigungen"
    },
    "blockedIOS": {
      "step1": "Öffne die Einstellungen-App",
      "step2": "Suche nach den Einstellungen für die ExperiRace App",
      "step3": "Erlaube die Benachrichtigungen"
    },
    "blockedDefault": {
      "step1": "Öffne die Seiteneinstellungen neben der Adressleiste des Browsers (normalerweise ein Zahnrad oder ein Schloss-Symbol) oder die Anwendungseinstellungen",
      "step2": "Suche nach den Einstellungen für Benachrichtigungen",
      "step3": "Erlaube die Benachrichtigungen"
    },
    "pwaInstall": {
      "message": "Auf iOS-Geräten kannst du Benachrichtigungen nur erhalten, wenn du die Webseite zu deinem Startbildschirm hinzufügst.",
      "toEnable": "Um Benachrichtigungen zu aktivieren, führe folgende Schritte aus:",
      "step1": "Tippe unten auf dem Bildschirm auf das Teilen-Symbol unter der Adressleiste des Browsers",
      "step2": "Wähle die Option \"Zum Startbildschirm hinzufügen\" und tippe dann auf \"Hinzufügen\" in der oberen rechten Ecke",
      "step3": "Finde das ExperiRace App-Symbol in deinen Anwendungen",
      "step4": "Öffne die Anwendung, wähle den Läufer aus, dem du folgen möchtest, und abonniere die Benachrichtigungen. Nutze ExperiRace von nun an über den Startbildschirm und nicht mehr über den Browser.",
      "versionErrorMessage": "Benachrichtigungen werden nur auf Geräten mit iOS 16.4 oder höher unterstützt.",
      "upgradeInstructions": "Bitte folge den detaillierten Anweisungen auf der Support-Seite von Apple, um dein iOS-Gerät zu aktualisieren. Du kannst die Anleitung hier aufrufen: {link}",
      "upgradeInstructionsUrl": "https://support.apple.com/de-de/HT204204"
    }
  },
  "feed": {
    "goTo": "{click} um die {live}-Übertragung anzusehen",
    "goToClickHere": "Klicke hier,",
    "goToLive": "Live",
    "broadcastOff": "Die Übertragung pausiert gerade...",
    "checkOutParticipants": "Schau dir die Live-Übertragungen der Läufer an"
  },
  "navigation": {
    "home": "Start",
    "about": "Uber",
    "faq": "FAQ",
    "addRace": "Deinen Lauf hinzufügen",
    "races": "Läufe"
  },
  "footer": {
    "discover": "Entdecken Sie weitere bevorstehende Übertragungen unter {0}"
  },
  "videographer": {
    "os": "OS",
    "app": "App",
    "battery": "Akku",
    "batterySaveMode": "Energiesparmodus",
    "batterySaveModeIsActive": "ist aktiv",
    "storage": "Speicher",
    "queue": "Warteschlange",
    "storageFree": "frei",
    "storageTotal": "gesamt",
    "queueVideos": "{0} Videos in der Warteschlange",
    "network": "Netzwerk",
    "networkLast1Min": "Letzte 1 Minute",
    "networkLast5Mins": "Letzte 5 Minuten",
    "networkSpeed": "Geschwindigkeit",
    "networkUploaded": "Hochgeladen",
    "lastDataFrom": "Letzte Daten von"
  },
  "map": {
    "outdoors": "Outdoor",
    "satellite": "Satellit",
    "streets": "Straßen",
    "light": "Hell",
    "dark": "Dunkel",
    "satelliteStreets": "Satellit & Straßen",
    "standard": "Standard"
  },
  "common": {
    "back": "Zurück",
    "unsubscribe": "abmelden",
    "closeDialog": "Dialog schließen",
    "clickHere": "Hier klicken",
    "broadcastedOnly": "Nur übertragen",
    "progress": "Fortschritt",
    "filterByType": "Nach Typ filtern",
    "broadcasted": "Übertragen",
    "uploadedAt": "Hochgeladen bei {0}",
    "closeEditor": "Editor schließen",
    "type": "Typ",
    "delete": "Löschen",
    "clone": "Klonen",
    "edit": "Bearbeiten",
    "cancel": "Abbrechen",
    "bib": "SN",
    "cameraPoint": "Kamerapunkt",
    "name": "Name",
    "racer": "Läufer",
    "track": "Strecke",
    "broadcast": "Übertragung",
    "language": "Sprachen",
    "loading": "Lädt...",
    "noRace": "Wir finden den gewünschten Lauf nicht",
    "noRunner": "Wir finden den gewünschten Läufer nicht",
    "noVideoUploaded": "Es wurde noch kein Video hochgeladen :(",
    "download": "Herunterladen",
    "raceTime": "Dauer des Laufes",
    "goalTime": "Ziel",
    "distance": "Distanz",
    "raceOver": "Der Lauf ist beendet",
    "raceNotYetStarted": "Der Lauf hat noch nicht begonnen",
    "lap": "Runde",
    "startCamera": "Startzeitpunkt",
    "finishCamera": "Zieleinlauf",
    "finishTimes": "Zieleinlaufzeit",
    "finished": "Beendet",
    "share": "Teilen",
    "search": "Suche",
    "comments": "{numberOfComments} Kommentare",
    "copyright": "Alle Rechte vorbehalten.",
    "backToHome": "Zurück zur Startseite",
    "na": "N/A",
    "naDetailed": "Keine Informationen über den Läufer",
    "noFinishTime": "Fehlende Zieleinlaufzeit",
    "noFinishTimeTooltip": "Für diesen Läufer wurde kein Ziel aufgezeichnet",
    "metrics": {
      "kilometer": "km",
      "meter": "m",
      "hm": "h:m"
    },
    "generalShotTypes": {
      "general": "Allgemein",
      "cameraPointIntro": "Kamerapunkt Intro",
      "beforeStart": "Loop vor dem Start",
      "afterStart": "Loop nach dem Start",
      "interview": "Interview",
      "repeatable": "Loop allgemein"
    },
    "languages": {
      "en-gb": "Englisch (Britisch)",
      "en-us": "Englisch (Amerikanisch)",
      "de-de": "Deutsch",
      "hu-hu": "Ungarisch"
    },
    "ordinals": {
      "one": "{0}. ",
      "two": "{0}. ",
      "few": "{0}. ",
      "other": "{0}. "
    }
  },
  "test": {
    "simple": "Test",
    "params": "Test {param1} {param2}",
    "directiveInterpolation": "Test {0} testing"
  },
  "$vuetify": {
    "badge": "Badge",
    "open": "Offen",
    "close": "Abgeschlossen",
    "dataIterator": {
      "noResultsText": "Keine passenden Datensätze gefunden",
      "loadingText": "Elemente werden geladen..."
    },
    "dataTable": {
      "itemsPerPageText": "Zeilen pro Seite:",
      "ariaLabel": {
        "sortDescending": "Absteigend sortiert.",
        "sortAscending": "Aufsteigend sortiert.",
        "sortNone": "Nicht sortiert.",
        "activateNone": "Aktivieren, um die Sortierung aufzuheben.",
        "activateDescending": "Aktivieren, um absteigend zu sortieren.",
        "activateAscending": "Aktivieren, um aufsteigend zu sortieren."
      },
      "sortBy": "Sortieren nach"
    },
    "dataFooter": {
      "itemsPerPageText": "Elemente pro Seite:",
      "itemsPerPageAll": "Alle",
      "nextPage": "Nächste Seite",
      "prevPage": "Vorherige Seite",
      "firstPage": "Erste Seite",
      "lastPage": "Letzte Seite",
      "pageText": "{0}-{1} von {2}"
    },
    "dateRangeInput": {
      "divider": "zu"
    },
    "datePicker": {
      "ok": "OK",
      "cancel": "Abbrechen",
      "range": {
        "title": "Daten auswählen",
        "header": "Daten eingeben"
      },
      "title": "Datum auswählen",
      "header": "Datum eingeben",
      "input": {
        "placeholder": "Datum eingeben"
      }
    },
    "noDataText": "Keine Daten verfügbar",
    "carousel": {
      "prev": "Vorheriges Bildmaterial",
      "next": "Nächstes Bildmaterial",
      "ariaLabel": {
        "delimiter": "Karussell-Dia {0} von {1}"
      }
    },
    "calendar": {
      "moreEvents": "{0} mehr"
    },
    "input": {
      "clear": "Löschen {0}",
      "prependAction": "{0} vorangestellte Aktion",
      "appendAction": "{0} beigefügte Aktion",
      "otp": "Bitte OTP-Zeichen eingeben {0}"
    },
    "fileInput": {
      "counter": "{0} Dateien",
      "counterSize": "{0} Dateien ({1} insgesamt)"
    },
    "timePicker": {
      "am": "MORGENS",
      "pm": "ABENDS"
    },
    "pagination": {
      "ariaLabel": {
        "root": "Seitennavigation",
        "next": "Nächste Seite",
        "previous": "Vorherige Seite",
        "page": "Gehe zu Seite {0}",
        "currentPage": "Seite {0}, Aktuelle Seite",
        "first": "Erste Seite",
        "last": "Letzte Seite"
      }
    },
    "stepper": {
      "next": "Nächste",
      "prev": "Vorherige"
    },
    "rating": {
      "ariaLabel": {
        "item": "Bewertung {0} von {1}"
      }
    },
    "loading": "Lädt...",
    "infiniteScroll": {
      "loadMore": "Keine mehr",
      "empty": "No more"
    }
  },
  "webshop": {
    "productUrl": "https://experirace.com/products/{productHandle}",
    "cartUrl": "https://experirace.com/cart/add?id={productVariantId}&quantity=1&properties[Bib%20number]={bibNumber}"
  },
  "10": "10",
  "25": "25",
  "50": "50",
  "100": "100"
}
