import * as Sentry from "@sentry/vue";
import { CaptureConsole, ExtraErrorData, HttpClient } from "@sentry/integrations";
import { buildEnvironment, runEnvironment, sentryDSN } from "@/config";
import type { App } from "vue";
import type { Router } from "vue-router";

export function setupSentry(app: App, router: Router) {
  if (runEnvironment === "test" || runEnvironment === "local") {
    return;
  }

  Sentry.init({
    app,
    environment: runEnvironment,
    dsn: sentryDSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", /^\//],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
      new CaptureConsole({
        levels: ["error"],
      }),
      new ExtraErrorData(),
      new HttpClient(),
    ],
    sampleRate: 1,
    sendDefaultPii: runEnvironment !== "live",
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    initialScope: {
      tags: {
        "build-environment": buildEnvironment,
      }
    }
  });
}
