<script setup lang="ts">
import PWAStep1Img from "@/assets/pwa_install1.jpg";
import PWAStep2Img from "@/assets/pwa_install2.jpg";
import PWAStep3Img from "@/assets/pwa_install3.jpg";

import { useI18n } from "vue-i18n";
const { t } = useI18n();

</script>

<template>
    {{ t("notifications.pwaInstall.message") }}

    <div class="notification-steps mt-3">
        <strong>{{ t("notifications.pwaInstall.toEnable") }}</strong>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                1. {{ t("notifications.pwaInstall.step1") }}
            </div>
            <img class="elevation-3" :src="PWAStep1Img" />
        </div>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                2. {{ t("notifications.pwaInstall.step2") }}
            </div>
            <img class="elevation-3" :src="PWAStep2Img" />
        </div>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                3. {{ t("notifications.pwaInstall.step3") }}
            </div>
            <img class="elevation-3" :src="PWAStep3Img" />
        </div>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                4. {{ t("notifications.pwaInstall.step4") }}
            </div>
        </div>
    </div>
</template>

<style scoped>
.notification-steps img {
  max-width: 100%;
}
</style>
