import type { Lifecycle } from "./common";
import { MemberRole } from "./member";
import type { RaceId } from "./race";

export enum Roles {
  ADMIN = "admin",
  SHOT_MANAGER = "shot_manager",
  RUNNER_MANAGER = "runner_manager",
  VIDEOGRAPHER = "videographer",
  RACE_ADMIN = "race_admin",
}

export type UserId = string;

export type User = {
  id: UserId;
  email: string;
  name: string;
  roles: Roles[];
  raceRoles: Record<RaceId, MemberRole[]>;
  lifecycle: Lifecycle;
};
