<script setup lang="ts">
import { ref, type Ref } from "vue";
import { useSelectedRaceStore } from "@/stores/selectedRace";
import { useSelectedParticipantStore } from "@/stores/selectedParticipant";
import { useNotificationStore } from "@/stores/notification";
import { unsubscribeFromParticipant } from "@/libs/notification/subscription";

const notifications = useNotificationStore();
const selectedParticipantStore = useSelectedParticipantStore();
const selectedRaceStore = useSelectedRaceStore();

import { useI18n } from "vue-i18n";
const { t } = useI18n();

const isDialogOpened: Ref<boolean> = ref(false);
const isLoading: Ref<boolean> = ref(false);

async function unsubscribe() {
  isLoading.value = true;

  try {
    await unsubscribeFromParticipant(
      selectedRaceStore.race!.id,
      selectedParticipantStore.participant!.id,
      notifications.settings.token!
    );
  } catch (e: any) {
    console.error("Problem during subscription removal", e);
  }

  isLoading.value = false;
  isDialogOpened.value = false;
}
</script>

<template>
  <v-dialog v-model="isDialogOpened" activator="parent" max-width="800">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col class="text-h5 pb-0">{{ t("pages.broadcastsParticipant.unsubscribeTitle") }}</v-col>
        </v-row>
        <v-row>
          <template v-if="!isLoading">
            <v-col>
              <p class="mb-2">
                <i18n-t keypath="pages.broadcastsParticipant.unsubscribeConfirm" tag="span">
                  <template v-slot:name>
                    <strong class="text-primary">{{ selectedParticipantStore.participant?.name }}</strong>
                  </template>
                </i18n-t>
              </p>
            </v-col>
          </template>

          <template v-if="isLoading">
            <v-col>
              <i18n-t keypath="pages.broadcastsParticipant.unsubscribeProgressMessage" tag="span">
                <template v-slot:name>
                  <strong class="text-primary">{{ selectedParticipantStore.participant?.name }}</strong>
                </template>
              </i18n-t>
            </v-col>
          </template>
        </v-row>
        <v-row v-if="isLoading">
          <v-col>
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!isLoading" color="primary" @click="isDialogOpened = false">{{ t("common.closeDialog") }}</v-btn>
        <v-btn v-if="!isLoading" variant="tonal" rounded="2" color="red" @click="unsubscribe()">{{
          t("common.unsubscribe")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
