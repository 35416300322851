import { Roles, type Race, type User } from "#/domain/types";
import { useUserStore } from "@/stores/user";
import { RouteName } from "./routeNames";
import type { RouteLocationNormalized, Router } from "vue-router";
import { getDoc } from "firebase/firestore";
import { doc } from "@/firebase/firestore";

export const authGuard = (router: Router) => async (to: RouteLocationNormalized) => {
  const authorized = await isAuthorized(to.name as RouteName, to.params.raceId as string);
  if (!authorized) {
    router.push({ name: RouteName.permissionDenied });
  }
};

async function isAuthorized(routeName: RouteName, raceId: string): Promise<boolean> {
  const authorizedRoutes = [RouteName.broadcastAdminCameraPoints, RouteName.broadcastAdminShotsGeneral];
  if (!authorizedRoutes.includes(routeName)) {
    return true;
  }
  const userStore = useUserStore();
  await userStore.loaded;
  if (isAuthorizedForAdminRoute(userStore.user!)) {
    return true;
  }
  const race = await getRace(raceId);
  return isOwner(userStore.user, race) || isEditor(userStore.user, race);
}

function isAuthorizedForAdminRoute(user: User | null): boolean {
  const userRoles = user?.roles || [];
  return intersects(userRoles, [Roles.ADMIN, Roles.RACE_ADMIN]);
}

function intersects<T>(a: T[], b: T[]): boolean {
  return a.some((item) => b.includes(item));
}

async function getRace(raceId: string): Promise<Race> {
  return getDoc(doc("races", raceId)).then((doc) => doc.data() as Race);
}

function isOwner(user: User | null, race: Race): boolean {
  return user?.id === race.owner.uid;
}

function isEditor(user: User | null, race: Race): boolean {
  if (!user) {
    return false;
  }
  return race.editorUids.includes(user.id);
}
