<script setup lang="ts">
import EdgeStep1Img from "@/assets/blocked_guide_edge1.jpg";
import EdgeStep2Img from "@/assets/blocked_guide_edge2.jpg";

import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
    {{ t("notifications.blockedMessage") }}

    <div class="notification-steps mt-3">
        <strong>{{ t("notifications.blockedToEnable") }}</strong>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                1. {{ t("notifications.blockedEdge.step1") }}
            </div>
            <img class="elevation-3" :src="EdgeStep1Img" />
        </div>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                2. {{ t("notifications.blockedEdge.step2") }}
            </div>
            <img class="elevation-3" :src="EdgeStep2Img" />
        </div>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                3. {{ t("notifications.blockedLastStep") }}
            </div>
        </div>
    </div>

</template>

<style scoped>
.notification-steps img {
  max-width: 100%;
}
</style>
