import { doc } from "@/firebase/firestore";
import { type User as FirebaseUser } from "firebase/auth";
import { useCurrentUser, useDocument } from "vuefire";
import { Roles, type User } from "#/domain/types";
import { defineStore } from "pinia";
import { type Ref, ref, computed, watch } from "vue";

export const useUserStore = defineStore("user", () => {
  const authUserLoaded = ref(false);
  const authUser: Ref<FirebaseUser | null | undefined> = useCurrentUser();
  const user: Ref<User | null> = ref(null);
  const isLoggedIn = computed(() => !!user.value?.id);

  const isAdmin = computed(() => {
    return !!user.value?.roles.includes(Roles.ADMIN);
  });

  const { pending } = useDocument<User | null>(
    computed(() => {
      if (!authUser.value?.uid) return null;
      return doc<User>("users/" + authUser.value?.uid);
    }),
    { target: user }
  );

  const isLoaded = computed(() => {
    return authUserLoaded.value && !pending.value;
  });

  watch(authUser, () => {
    authUserLoaded.value = true;

    if (!authUser.value?.uid) {
      user.value = null;
    }
  });

  const loaded = ref(
    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (isLoaded.value) {
          clearInterval(interval);
          resolve(undefined);
        }
      }, 10);
    })
  );

  return {
    loaded,
    authUser,
    user,
    isLoggedIn,
    isLoaded,
    isAdmin,
  };
});
