<script setup lang="ts">
import { useSelectedRaceStore } from "@/stores/selectedRace";

const selectedRaceStore = useSelectedRaceStore();
</script>

<template>
  <v-toolbar-title
    id="broadcast-header"
    v-if="selectedRaceStore.race"
    class="d-flex align-center justify-center mx-0"
  >
    <v-container class="px-0 font-racing">
      <v-row no-gutters class="d-flex justify-center">
        <v-btn size="lg" class="text-truncate" block :active="false" :to="{ name: 'broadcast', params: { raceId: selectedRaceStore.race.id } }">
            {{ selectedRaceStore.race.name || "" }}
        </v-btn>
      </v-row>
    </v-container>
  </v-toolbar-title>

  <v-spacer id="broadcast-header" v-else />
</template>
