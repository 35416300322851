import { ref, type Ref } from "vue";
import { defineStore } from "pinia";
import type { Race } from "#/domain/types";

export const useSelectedRaceStore = defineStore("selectedRace", () => {
  const race: Ref<Race | null> = ref(null);

  function $reset() {
    race.value = null;
  }

  return {
    race,
    $reset,
  };
});