export enum NotificationPermissionStatus {
  GRANTED = "granted",
  DENIED = "denied",
  DEFAULT = "default",
}

export type NotificationSettings = {
  token: string | null;
  statusOfNotificationPermission: NotificationPermissionStatus;
};
