import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { NotificationPermissionStatus, type NotificationSettings, type NotificationSubscription } from "#/domain/types";
import { type Ref, computed } from "vue";
import { useCollection } from "vuefire";
import { query } from "@/firebase/firestore";
import { where, limit } from "firebase/firestore";
import { fetchToken } from "@/libs/notification/fetchToken";

export const useNotificationStore = defineStore("notification", () => {
  const settings = useStorage("notificationSettings", {
    token: null,
    statusOfNotificationPermission: NotificationPermissionStatus.DEFAULT,
  } as NotificationSettings);

 function isSubscribedToParticipant(
    raceId: Ref<string | null | undefined>,
    participantId: Ref<string | null | undefined>,
  ) {
    const notificationSubscription = getNotificationSubscription(raceId, participantId);
    return computed(() => {
      return notificationSubscription.notificationSubscriptons.value?.length > 0;
    });
  }

 function getNotificationSubscription(
    raceId: Ref<string | null | undefined>,
    participantId: Ref<string | null | undefined>
  ) {
    const {
      data: notificationSubscriptons,
      pending: isNotificationSubscriptonsPending,
      error: notificationSubscriptonsError,
    } = useCollection<NotificationSubscription>(
      computed(() => {
        if (!raceId.value || !participantId.value || !settings.value?.token) return null;
        return query(
          `races/${raceId.value}/participants/${participantId.value}/notificationSubscriptions`,
          where("notificationToken", "==", settings.value.token),
          limit(1)
        );
      })
    );

    return {
      notificationSubscriptons,
      isNotificationSubscriptonsPending,
      notificationSubscriptonsError,
    };
  }

  async function requestPermission() {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.debug("Notification permission granted.");
      settings.value.statusOfNotificationPermission = NotificationPermissionStatus.GRANTED;

      try {
        const token = await fetchToken();
        settings.value.token = token;
      } catch (e) {
        settings.value.statusOfNotificationPermission = NotificationPermissionStatus.DENIED;
        settings.value.token = null;
      }
    } else {
      console.debug("Notification permission denied.");
      settings.value.statusOfNotificationPermission = NotificationPermissionStatus.DENIED;
      settings.value.token = null;
    }
  }

  function $reset() {
    settings.value.token = null;
    settings.value.statusOfNotificationPermission = NotificationPermissionStatus.DEFAULT;
  }

  return {
    settings,
    $reset,
    requestPermission,
    isSubscribedToParticipant
  };
});
