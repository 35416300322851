import { Timestamp, type DocumentData, type QueryDocumentSnapshot, type WithFieldValue } from "firebase/firestore";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertTimestampsToDates(obj: any): any {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (obj instanceof Timestamp) {
    return obj.toDate();
  }

  if (Array.isArray(obj)) {
    return obj.map(convertTimestampsToDates);
  }

  if (typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, convertTimestampsToDates(value)])
    );
  }

  return obj;
}

export function getConverter<T>() {
  return {
    toFirestore(data: WithFieldValue<T>): DocumentData {
      if (!data) return {};

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...dataWithoutId } = data as object & { id: string };
      return dataWithoutId as DocumentData;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): T {
      const dataWithoutId = snapshot.data() as T;
      const data = { ...dataWithoutId, id: snapshot.id };

      return convertTimestampsToDates(data as object) as T;
    },
  };
}
