import { ref } from "vue";
import { defineStore } from "pinia";

export const useCurrentPageStore = defineStore('currentPage', () => {
  const utmMedium = ref("");

  function $reset() {
    utmMedium.value = "";
  }

  return {
    $reset,
    utmMedium,
  };
});