{
  "title": "ExperiRace",
  "pages": {
    "home": {
      "title": "Home",
      "welcome": "Hi Runners!",
      "subtitle": "You are in the best place to experirace"
    },
    "login": {
      "title": "Login",
      "welcome": "Login to the application",
      "googleLogin": "Login with Google",
      "alreadyLoggedIn": "Welcome to ExperiRace!"
    },
    "generalBroadcast": {
      "participantBtnTooltip": "Open runner's personal broadcast",
      "followBtn": "Follow Your Favourite Runner"
    },
    "broadcast": {
      "promote": "We broadcast this race, so you can join and experience the thrill of racing like never before.",
      "liveButtonBeforeRace": "The Live Broadcast Starts Soon",
      "liveButtonDuringRace": "Watch the Live Broadcast",
      "liveButtonAfterRace": "Watch the Race Replay",
      "goToBroadcast": "Go to the runner's broadcast",
      "broadcast": "Full access",
      "broadcasted": "Full access",
      "track": "Upgrade available",
      "tracked": "Upgrade available",
      "feedTitle": "Click here to watch",
      "feedSubtitle": "The live broadcast",
      "runners": "Registered runners",
      "tableBeforeRace": "Race participants",
      "tableDuringRace": "Race progress",
      "tableAfterRace": "Race results",
      "finishRaceTimeSecBeforeFinish": "Predicted finish time",
      "finishRaceTimeSecAfterFinish": "Finish time",
      "estimatedDistance": "Predicted distance now",
      "lastAppearance": "Last tracked appearance",
      "sort": "Sort",
      "bib": {
        "asc": "Bibs ascending",
        "desc": "Bibs descending"
      },
      "participant": {
        "asc": "Name A-Z",
        "desc": "Name Z-A"
      },
      "progress": {
        "asc": "Leaders",
        "desc": "Tail-enders"
      }
    },
    "broadcastsParticipant": {
      "noMoment": "There are no moments for this runner :(",
      "raceNotStartedLabel": "The race did not start yet!",
      "raceNotStartedTime": "Starts at {time}",
      "raceStartedNoMomentLabel": "Race started",
      "raceStartedNoMomentSoon": "Stay tuned for the start video...",
      "raceFinishedNoRunnerLabel": "The race is officially over!",
      "nextCameraPointWithEta": "We expect the runner to reach {distance} at {eta}",
      "nextCameraPointAnyTime": "We expect the runner to reach {distance} at any time",
      "finishTitle": "{0} finished the race",
      "finishTime1": "Race time was {0}",
      "finishTime2": " which is {0}",
      "finishTimeFasterToGoal": " faster than planned!",
      "finishTimeSlowerToGoal": " slower than planned!",
      "finishTimeFasterToAvg": " faster than average!",
      "finishTimeSlowerToAvg": " slower than average!",
      "calendarEventTitle": "{name}'s experirace",
      "calendarEventDescription": "Follow {name}'s broadcast!\n\nURL: {url}",
      "feedback": "Please {button} to make this service better",
      "feedbackButton": "Provide feedback",
      "getUpdatesClickHere": "Subscribe to updates",
      "getUpdatesBarTooltip": "Subscribe to get update notifications about this runner",
      "unsubscribeClickHere": "Unsubscribe from updates",
      "unsubscribeBarTooltip": "Unsubscribe from update notifications about this runner",
      "subscribeTitle": "Subscribe to update notifications",
      "subscribeInitialMessage": "Simply accept notifications in the browser popup that just opened, and you will be notified whenever we have an update for this runner.",
      "subscribeSuccessfulTitle": "Successfully subscribed to update notifications",
      "subscribeProgressMessage": "Subscribing to {name}'s updates...",
      "unsubscribeTitle": "Unsubscribe from notifications",
      "unsubscribeProgressMessage": "Unsubscribing from {name}'s updates...",
      "unsubscribeConfirm": "Are you sure you want to unsubscribe from {name}'s updates?",
      "orderPostRaceVideo": "Purchase Post Event video",
      "orderParticipantBroadcast": "Unlock runner broadcast",
      "goToPostRaceVideo": "Watch Post Event video",
      "lapN": "Lap {0}",
      "previewMoment": {
        "bannerTitle": "Runner broadcast {0}",
        "bannerTitlePreview": "preview",
        "title": "Unlock {0}'s videos",
        "preview": "This is just a preview. ",
        "descriptionLine1": "If you want to unlock the videos in their {quality}, you have to {purchase} this runner's broadcast. Additionally, your purchase includes an {postrace}!",
        "quality": "original quality and resolution",
        "purchase": "purchase",
        "postrace": "exclusive post-race highlights video",
        "descriptionLine2": "{0} to read more about it.",
        "clickhere": "Click here",
        "order": "Unlock"
      }
    },
    "broadcastsParticipantsPostrace": {
      "title": "Post Race Video",
      "smallTitle": "Look at my run on {0}",
      "like": "If you liked it, please share my run!"
    },
    "translationTest": {
      "title": "Test title"
    },
    "admin": {
      "generalShots": {
        "deleteTitle": "Delete general shot",
        "deleteMessage": "Do you want to delete this general shot?",
        "noRepeatStart": "Do not repeat it for",
        "noRepeatEnd": "minutes",
        "participants": "Participants of the interview",
        "generalDescription": "An atmosphere shot played once in a broadcast",
        "beforeAfterStartDescription": "Shot played repeatedly when there is no other shot",
        "beforeStart": "before the start",
        "afterStart": "after the start",
        "cameraPointIntro": "Intro of the camera point",
        "cameraPointIntroOn": "on",
        "cameraPointIntroTrack": "track",
        "missingCameraPoint": "Missing camera point",
        "missingTrack": "Missing track",
        "missingInterviewee": "Missing interviewee",
        "interviewDescription": "Showing interview once in a broadcast feed with",
        "repeatableDescription": "An atmosphere shot which can repeat once every",
        "repeatableDescriptionEnd": "minutes",
        "addNewShot": "Upload new shot",
        "preparingShot": "Preparing shot...",
        "text": "Add text to shot"
      },
      "cameraPoints": {
        "pageTitle": "Camera Point",
        "pageSubtitle": "Setup & Monitoring",
        "selectTracks": "Select track(s)",
        "zoomToTracks": "Zoom to tracks",
        "showReceptionMetrics": "Show network reception metrics",
        "showMoments": "Show moments",
        "showMomentCircles": "Show moment radiuses",
        "changeLayers": "Change layer",
        "startPoints": "Track(s) start",
        "finishPoints": "Track(s) finish",
        "name": "Name",
        "rank": "Rank",
        "coordinates": "Coordinates",
        "latitude": "Latitude",
        "longitude": "Longitude",
        "altitude": "Altitude",
        "track": "Track",
        "positionOnTracks": "Position on track(s)",
        "claimedBy": "Claimed by",
        "lastClaimBy": "Last claim by",
        "isStart": "Start",
        "isFinish": "Finish",
        "distance": "Distance",
        "elevationGain": "Elevation gain",
        "plannedTime": "Planned time",
        "tracks": "Tracks",
        "averageUploadSpeed": "Average upload speed",
        "networkSpeedMeasurement": "Network speed",
        "videoLengthUploadDurationRatio": "Video length to upload time",
        "videoLengthUploadDurationRealtime": "Realtime upload",
        "videoLengthUploadDurationSlow": "Not realtime upload",
        "moment": "Video",
        "momentId": "Moment ID",
        "shotId": "Shot ID",
        "trackId": "Track ID",
        "recordingFinishedAt": "Recording finished at",
        "uploadStartedAt": "Upload started at",
        "cameraPointOwnerName": "Videographer's name",
        "title": {
          "cameraPoints": "Camera points",
          "videographers": "Videographers"
        },
        "videographers": {
          "cameraPointNames": "Camera(s)",
          "name": "Name",
          "deviceName": "Device",
          "battery": "Battery",
          "queueSize": "Video Queue",
          "diskSize": "Free Disk",
          "lastCameraPointLoginAt": "Last login to camera"
        }
      }
    },
    "permissionDenied": {
      "title": "Permission denied",
      "message": "You don't have permission to access this page"
    }
  },
  "broadcast": {
    "raceStartSoon": "The race will start soon",
    "startCameraPoint": "Start",
    "finishCameraPoint": "Finish"
  },
  "notifications": {
    "blockedMessage": "It seems you've previously denied permission to display notifications from this website.",
    "blockedToEnable": "To receive update notifications for your runner, please allow notifications:",
    "blockedLastStep": "You may have to reload the page and subscribe again",
    "blockedChrome": {
      "step1": "Click the lock icon in your browser's address bar",
      "step2": "Allow the notifications"
    },
    "blockedSafari": {
      "step1": "Open the settings of your browser",
      "step2": "Go to the Website settings",
      "step3": "Choose the Notifications and then allow the notifications for the app.experirace.com website"
    },
    "blockedEdge": {
      "step1": "Click on the lock icon on your browser's address bar",
      "step2": "Allow the notifications"
    },
    "blockedAndroid": {
      "step1": "Tap on the bell or page settings icon right to the browser's address bar",
      "step2": "Tap on the notifications",
      "step3": "Allow the notifications"
    },
    "blockedIOS": {
      "step1": "Open the Settings app",
      "step2": "Search for the ExperiRace app settings",
      "step3": "Allow the notifications"
    },
    "blockedDefault": {
      "step1": "Open the page settings next to the browser's address bar (usually a cog or a lock icon) or the application settings",
      "step2": "Search for the Notification settings",
      "step3": "Allow the notifications"
    },
    "pwaInstall": {
      "message": "In iOS devices, you can only receive notifications by adding the website to your home screen.",
      "toEnable": "To enable notifications, follow these steps:",
      "step1": "On the bottom of the screen, tap on the share icon below the browser’s address bar",
      "step2": "Choose the 'Add to Home Screen' option and then tap on 'Add' on the top right corner",
      "step3": "Find the ExperiRace app icon among your applications",
      "step4": "Open the application, select the runner you want to follow and subscribe to the notifications. From now on, use ExperiRace from the home screen and not from the browser.",
      "versionErrorMessage": "Notifications are supported only on devices running iOS 16.4 or later.",
      "upgradeInstructions": "Please follow the detailed instructions on the Apple support page for updating your iOS device. You can access the guide here: {link}",
      "upgradeInstructionsUrl": "https://support.apple.com/en-us/HT204204"
    }
  },
  "feed": {
    "goTo": "{click} to watch the {live} race broadcast",
    "goToClickHere": "Click here",
    "goToLive": "live",
    "broadcastOff": "Broadcast is taking a break...",
    "checkOutParticipants": "Check out the runners' live broadcasts"
  },
  "navigation": {
    "home": "Home",
    "about": "About",
    "faq": "FAQ",
    "addRace": "Add Your Race",
    "races": "Races"
  },
  "footer": {
    "discover": "Discover more upcoming broadcasts at {0}"
  },
  "videographer": {
    "os": "OS",
    "app": "App",
    "battery": "Battery",
    "batterySaveMode": "Battery save mode",
    "batterySaveModeIsActive": "is active",
    "storage": "Storage",
    "queue": "Queue",
    "storageFree": "free",
    "storageTotal": "total",
    "queueVideos": "{0} videos queued",
    "network": "Network",
    "networkLast1Min": "Last 1 min",
    "networkLast5Mins": "Last 5 mins",
    "networkSpeed": "Speed",
    "networkUploaded": "Uploaded",
    "lastDataFrom": "Last data from"
  },
  "map": {
    "outdoors": "Outdoors",
    "satellite": "Satellite",
    "streets": "Streets",
    "light": "Light",
    "dark": "Dark",
    "satelliteStreets": "Satellite & Streets",
    "standard": "Standard"
  },
  "common": {
    "back": "Back",
    "unsubscribe": "Unsubscribe",
    "closeDialog": "Close dialog",
    "clickHere": "Click here",
    "broadcastedOnly": "Broadcasted only",
    "progress": "Progress",
    "filterByType": "Filter by type",
    "broadcasted": "Broadcasted",
    "uploadedAt": "Uploaded at {0}",
    "closeEditor": "Close editor",
    "type": "Type",
    "delete": "Delete",
    "clone": "Clone",
    "edit": "Edit",
    "cancel": "Cancel",
    "bib": "Bib",
    "cameraPoint": "Camera point",
    "name": "Name",
    "racer": "Runner",
    "track": "Track",
    "broadcast": "Broadcast",
    "language": "Languages",
    "loading": "Loading...",
    "noRace": "We don't find the requested race",
    "noRunner": "We don't find the requested runner",
    "noVideoUploaded": "No video uploaded yet :(",
    "download": "Download",
    "raceTime": "Race time",
    "goalTime": "Goal",
    "distance": "Distance",
    "raceOver": "Race is over",
    "raceNotYetStarted": "Race is not yet started",
    "lap": "Lap",
    "startCamera": "Start moment",
    "finishCamera": "Finish moment",
    "finishTimes": "Finish Time",
    "finished": "Finished",
    "share": "Share",
    "search": "Search",
    "comments": "{numberOfComments} comments",
    "copyright": "All rights reserved.",
    "backToHome": "Back to home",
    "na": "N/A",
    "naDetailed": "No information about the runner",
    "noFinishTime": "Missing finish time",
    "noFinishTimeTooltip": "No finish tracked for this runner",
    "metrics": {
      "kilometer": "km",
      "meter": "m",
      "hm": "h:m"
    },
    "generalShotTypes": {
      "general": "General",
      "cameraPointIntro": "Camera Point Intro",
      "beforeStart": "Looping before start",
      "afterStart": "Looping after start",
      "interview": "Interview",
      "repeatable": "Looping general"
    },
    "languages": {
      "en-gb": "English (British)",
      "en-us": "English (American)",
      "de-de": "German",
      "hu-hu": "Hungarian"
    },
    "ordinals": {
      "one": "{0}st",
      "two": "{0}nd",
      "few": "{0}rd",
      "other": "{0}th"
    }
  },
  "test": {
    "simple": "testing",
    "params": "testing {param1} {param2}",
    "directiveInterpolation": "testing {0} testing"
  },
  "$vuetify": {
    "badge": "Badge",
    "open": "Open",
    "close": "Close",
    "dataIterator": {
      "noResultsText": "No matching records found",
      "loadingText": "Loading items..."
    },
    "dataTable": {
      "itemsPerPageText": "Rows per page:",
      "ariaLabel": {
        "sortDescending": "Sorted descending.",
        "sortAscending": "Sorted ascending.",
        "sortNone": "Not sorted.",
        "activateNone": "Activate to remove sorting.",
        "activateDescending": "Activate to sort descending.",
        "activateAscending": "Activate to sort ascending."
      },
      "sortBy": "Sort by"
    },
    "dataFooter": {
      "itemsPerPageText": "Items per page:",
      "itemsPerPageAll": "All",
      "nextPage": "Next page",
      "prevPage": "Previous page",
      "firstPage": "First page",
      "lastPage": "Last page",
      "pageText": "{0}-{1} of {2}"
    },
    "dateRangeInput": {
      "divider": "to"
    },
    "datePicker": {
      "ok": "OK",
      "cancel": "Cancel",
      "range": {
        "title": "Select dates",
        "header": "Enter dates"
      },
      "title": "Select date",
      "header": "Enter date",
      "input": {
        "placeholder": "Enter date"
      }
    },
    "noDataText": "No data available",
    "carousel": {
      "prev": "Previous visual",
      "next": "Next visual",
      "ariaLabel": {
        "delimiter": "Carousel slide {0} of {1}"
      }
    },
    "calendar": {
      "moreEvents": "{0} more"
    },
    "input": {
      "clear": "Clear {0}",
      "prependAction": "{0} prepended action",
      "appendAction": "{0} appended action",
      "otp": "Please enter OTP character {0}"
    },
    "fileInput": {
      "counter": "{0} files",
      "counterSize": "{0} files ({1} in total)"
    },
    "timePicker": {
      "am": "AM",
      "pm": "PM"
    },
    "pagination": {
      "ariaLabel": {
        "root": "Pagination Navigation",
        "next": "Next page",
        "previous": "Previous page",
        "page": "Go to page {0}",
        "currentPage": "Page {0}, Current page",
        "first": "First page",
        "last": "Last page"
      }
    },
    "stepper": {
      "next": "Next",
      "prev": "Previous"
    },
    "rating": {
      "ariaLabel": {
        "item": "Rating {0} of {1}"
      }
    },
    "loading": "Loading...",
    "infiniteScroll": {
      "loadMore": "Load more",
      "empty": "No more"
    }
  },
  "webshop": {
    "productUrl": "https://experirace.com/products/{productHandle}",
    "cartUrl": "https://experirace.com/cart/add?id={productVariantId}&quantity=1&properties[Bib%20number]={bibNumber}"
  },
  "10": "10",
  "25": "25",
  "50": "50",
  "100": "100"
}
