import "@mdi/font/css/materialdesignicons.css";

import { createApp } from "vue";

import App from "./App.vue";
import { createRouter } from "./router";

import { createPinia } from "./plugins/pinia";
import { vuetify } from "./plugins/vuetify";
import { i18n } from "./plugins/i18n";
import { setupSentry } from "./plugins/sentry";

import { VueFire, VueFireAuth } from "vuefire";
import { firebaseApp } from "@/firebase/firestore";
import VueCookies from "vue-cookies";
import { createHead } from "@unhead/vue";

const app = createApp(App);

app.use(VueCookies, {
  expires: "7d",
});
app.use(i18n);

const router = createRouter(i18n);
app.use(router);

app.use(vuetify);
app.use(createPinia());

app.use(createHead());

setupSentry(app, router);

app.use(VueFire, {
  firebaseApp,
  // eslint-disable-next-line
  modules: [VueFireAuth()],
});

app.mount("#app");
