import { webpushToken } from "@/config";
import { getMessaging, getToken } from "firebase/messaging";

export async function fetchToken() {
  try {
    const currentToken = await getToken(getMessaging(), {
      vapidKey: webpushToken,
    });

    if (currentToken) {
      console.debug("Got FCM token", currentToken);
      return currentToken;
    } else {
      console.debug("No registration token available. Should request permission to generate one.");
      throw Error("No registration token");
    }
  } catch (err) {
    console.error("An error occurred while retrieving token. ", err);
    throw Error("Error fetching token");
  }
}
