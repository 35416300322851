<script setup lang="ts">
import { useSelectedRaceStore } from "@/stores/selectedRace";
import { useSelectedParticipantStore } from "@/stores/selectedParticipant";

import { useI18n } from "vue-i18n";
const { t } = useI18n();

const selectedRaceStore = useSelectedRaceStore();
const selectedParticipantStore = useSelectedParticipantStore();
</script>

<template>
  <v-toolbar-title
    id="broadcast-header"
    v-if="selectedRaceStore.race"
    class="d-flex align-center justify-center mx-0"
  >
    <v-container class="px-0 font-racing">
      <v-row v-if="selectedParticipantStore.participant" no-gutters class="flex-nowrap">
        <v-col class="d-flex align-center flex-grow-0">
          <div class="px-2 py-1 mr-3 bg-secondary text-primary rounded-lg text-body-2 text-xs-body-3 font-racing">
            #{{ selectedParticipantStore.participant.bib }}
          </div>
        </v-col>
        <v-col class="text-truncate justify-start">
          <div class="text-truncate text-xs-body-2">
            {{ selectedParticipantStore.participant.name }}
          </div>
          <v-btn
            block
            :active="false"
            :to="{ name: 'broadcast', params: { raceId: selectedRaceStore.race.id } }"
            class="justify-start pa-0 mt-n2 h-auto mt-sm-n1 text-left text-truncate text-body-2 text-xs-body-3 font-racing text-medium-emphasis">
            {{ selectedRaceStore.race.name || "" }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-else no-gutters class="d-flex justify-center">
        <v-btn size="lg" class="text-truncate" block :active="false" :to="{ name: 'broadcast', params: { raceId: selectedRaceStore.race.id } }">
            {{ selectedRaceStore.race.name || "" }}
        </v-btn>
        <v-btn rounded="2" variant="tonal" color="primary" size="x-small" :active="false" class="mt-1 py-1 text-truncate flex-grow-0 font-normal font-weight-bold" :to="{ name: 'broadcast', params: { raceId: selectedRaceStore.race.id } }">
          {{ t("pages.generalBroadcast.followBtn") }}
          <v-icon size="small">mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-toolbar-title>

  <v-spacer id="broadcast-header" v-else />
</template>
