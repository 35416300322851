<script setup lang="ts">
import { computed } from "vue";
import { useSelectedParticipantStore } from "@/stores/selectedParticipant";
import { useSelectedRaceStore } from "@/stores/selectedRace";

import SubscribeToParticipant from "@/components/broadcast/SubscribeToParticipant.vue";
import UnsubscribeFromParticipant from "./broadcast/UnsubscribeFromParticipant.vue";

import { useNotificationStore } from "@/stores/notification";
const notifications = useNotificationStore();

import { useI18n } from "vue-i18n";
const { t } = useI18n();

const selectedParticipantStore = useSelectedParticipantStore();
const selectedRaceStore = useSelectedRaceStore();

const raceId = computed(() => selectedRaceStore.race?.id);
const participantId = computed(() => selectedParticipantStore.participant?.id);
const isSubscribed = notifications.isSubscribedToParticipant(raceId, participantId);
</script>

<template>
  <template v-if="selectedParticipantStore.participant?.id">
    <template v-if="!isSubscribed">
      <v-tooltip :text="t('pages.broadcastsParticipant.getUpdatesBarTooltip')">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-bell"
            density="comfortable"
            class="app-bar-button subscribe-to-participant-in-navbar"
          >
            <v-icon>mdi-bell</v-icon>
            <subscribe-to-participant></subscribe-to-participant>
          </v-btn>
        </template>
      </v-tooltip>
    </template>

    <template v-else>
      <v-tooltip :text="t('pages.broadcastsParticipant.unsubscribeBarTooltip')">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-bell-check"
            color="primary"
            density="comfortable"
            class="app-bar-button unsubscribe-to-participant-in-navbar"
          >
            <v-icon>mdi-bell-check</v-icon>
            <unsubscribe-from-participant></unsubscribe-from-participant>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
  </template>
</template>

<style scoped>
.app-bar-button {
  transition: all 0.35s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.hidden {
  opacity: 0;
  transform: scale(0);
}
</style>
