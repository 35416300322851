import { createRouter as createVueRouter, createWebHistory, type Router } from "vue-router";
import type { I18n } from "vue-i18n";
import { setI18n } from "../plugins/i18n";
import { RouteName } from "./routeNames";
import { authGuard } from "./authGuard";

export const createRouter = (i18n: I18n): Router => {
  const router = createVueRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: "/",
        name: RouteName.home,
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/login",
        name: RouteName.login,
        component: () => import("../views/user/Login.vue"),
      },
      {
        path: "/broadcasts/:raceId",
        name: RouteName.broadcast,
        component: () => import("../views/broadcast/Home.vue"),
      },
      {
        path: "/broadcasts/:raceId/admin/camera-points",
        name: RouteName.broadcastAdminCameraPoints,
        component: () => import("../views/broadcast/admin/CameraPoints.vue"),
      },
      {
        path: "/broadcasts/:raceId/admin/shots/general",
        name: RouteName.broadcastAdminShotsGeneral,
        component: () => import("../views/broadcast/admin/GeneralShots.vue"),
      },
      {
        path: "/broadcasts/:raceId/feed",
        name: RouteName.broadcastsFeed,
        component: () => import("../views/broadcast/Feed.vue"),
      },
      {
        path: "/broadcasts/:raceId/participants",
        name: RouteName.broadcastsParticipants,
        component: () => import("../views/broadcast/Participants.vue"),
      },
      {
        path: "/broadcasts/:raceId/participants/:participantId",
        name: RouteName.broadcastsParticipant,
        component: () => import("../views/broadcast/Participant.vue"),
      },
      {
        path: "/broadcasts/:raceId/participants/:participantId/postrace",
        name: RouteName.broadcastsParticipantsPostrace,
        component: () => import("../views/broadcast/Postrace.vue"),
      },
      {
        path: "/translation-test",
        name: RouteName.translationTest,
        component: () => import("../views/other/TranslationTest.vue"),
      },
      {
        path: "/lab/media-recorder",
        name: RouteName.mediaRecorder,
        component: () => import("../views/lab/MediaRecorder.vue"),
      },
      {
        path: "/lab/push",
        name: RouteName.push,
        component: () => import("../views/lab/Push.vue"),
      },
      {
        path: "/permission-denied",
        name: RouteName.permissionDenied,
        component: () => import("../views/PermissionDenied.vue"),
      },
    ],
  });

  router.beforeEach(async (to) => {
    try {
      const langSpecifiedInQuery: string | null = Array.isArray(to.query.lang) ? to.query.lang[0] : to.query.lang;
      await setI18n(i18n, langSpecifiedInQuery || undefined);
    } catch (e) {
      console.error(e);
    }
  });

  router.beforeEach(authGuard(router));

  return router;
};
