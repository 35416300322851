export enum RouteName {
  home = "home",
  login = "login",
  broadcast = "broadcast",
  broadcastAdminCameraPoints = "broadcastAdminCameraPoints",
  broadcastAdminShotsGeneral = "broadcastAdminShotsGeneral",
  broadcastsFeed = "broadcastsFeed",
  broadcastsParticipant = "broadcastsParticipant",
  broadcastsParticipants = "broadcastsParticipants",
  broadcastsParticipantsPostrace = "broadcastsParticipantsPostrace",
  translationTest = "translationTest",
  mediaRecorder = "mediaRecorder",
  push = "push",
  permissionDenied = "permissionDenied",
}
