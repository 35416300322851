<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
    {{ t("notifications.blockedMessage") }}

    <div class="notification-steps mt-3">
        <strong>{{ t("notifications.blockedToEnable") }}</strong>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                1. {{ t("notifications.blockedDefault.step1") }}
            </div>
            <div class="bg-blue pa-2 font-weight-bold">
                2. {{ t("notifications.blockedDefault.step2") }}
            </div>
            <div class="bg-blue pa-2 font-weight-bold">
                3. {{ t("notifications.blockedDefault.step3") }}
            </div>
            <div class="bg-blue pa-2 font-weight-bold">
                4. {{ t("notifications.blockedLastStep") }}
            </div>
        </div>
    </div>

</template>

<style scoped>
.notification-steps img {
  max-width: 100%;
}
</style>
