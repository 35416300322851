import { ref, type Ref } from "vue";
import { defineStore } from "pinia";
import type { Participant } from "#/domain/types";

export const useSelectedParticipantStore = defineStore('selectedParticipant', () => {
  const participant: Ref<Participant | null> = ref(null);

  function $reset() {
    participant.value = null;
  }

  return {
    participant,
    $reset,
  };
});