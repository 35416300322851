import { domain } from "@/config";
import { getFunctions, httpsCallable } from "firebase/functions";

export async function subscribeToParticipant(raceId: string, participantId: string, notificationToken: string, locale: string) {
  const functions = getFunctions(undefined, domain);

  const subscribeToParticipantAPI = httpsCallable(functions, "api/subscribeToParticipant");
  const result = await subscribeToParticipantAPI({ raceId, participantId, notificationToken, locale });

  console.debug("Subscribing to participant result", result);
}

export async function unsubscribeFromParticipant(raceId: string, participantId: string, notificationToken: string) {
  const functions = getFunctions(undefined, domain);

  const unsubscribeFromParticipantAPI = httpsCallable(functions, "api/unsubscribeFromParticipant");
  const result = await unsubscribeFromParticipantAPI({ raceId, participantId, notificationToken });

  console.debug("Unsubscribing from participant result", result);
}