import { connectAuthEmulator, signInWithPopup } from "firebase/auth";
import { getCurrentUser } from "vuefire";
import type { Auth } from "@firebase/auth";
import { useDocument, serverTimestamp, setDoc } from "@/firebase/firestore";
import type { User, WithoutId } from "#/domain/types";

import { GoogleAuthProvider, type User as AuthUser } from "firebase/auth";
import { useFirestoreEmulator } from "@/config";

export const googleAuthProvider = new GoogleAuthProvider();

export async function signIn(auth: Auth) {
  if (useFirestoreEmulator) {
    connectAuthEmulator(auth!, "http://127.0.0.1:9099");
  }

  try {
    await signInWithPopup(auth!, new GoogleAuthProvider());

    const authUser = await getCurrentUser();
    console.debug(`Sign in done with ${authUser?.uid}`);

    if (!authUser?.uid || !authUser?.email) {
      throw new Error("User couldn't log in with the auth provider");
    }

    const { promise: userPromise } = useDocument<User>("users", authUser.uid);
    const user = await userPromise.value;

    if (!user?.id) {
      console.debug(`User not found in DB, creating new user for ${authUser.uid}...`);
      await createNewUser(authUser);
    }
  } catch (e) {
    console.error("Failed signing in", e);
  }
}

const createNewUser = async (authUser: AuthUser) => {
  await setDoc<WithoutId<User>>(`users/${authUser.uid}`, {
    email: authUser.email || "",
    name: authUser.displayName || "",
    roles: [],
    raceRoles: {},
    lifecycle: {
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    },
  });
};

export async function logout(auth: Auth) {
  const user = await getCurrentUser();

  if (auth && user?.uid) {
    console.debug(`User ${user.displayName} [${user.uid}] found, logging out...`);
    await auth.signOut();
  }
}
