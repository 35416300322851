<script lang="ts" setup>
import dayjs from "dayjs";
import LanguageSelector from "@/components/LanguageSelector.vue";
import { useSelectedRaceStore } from "@/stores/selectedRace";
import { useCurrentTimeStore } from "@/stores/currentTime";
import { useCurrentPageStore } from "@/stores/currentPage";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const selectedRaceStore = useSelectedRaceStore();
const currentPageStore = useCurrentPageStore();
const currentTimeStore = useCurrentTimeStore();
</script>

<template>
  <v-container>
    <v-row no-gutters>
      <v-col class="d-flex justify-center flex-wrap">
        <i18n-t keypath="footer.discover" tag="span">
          <a
            class="app-footer__discover"
            :href="`https://experirace.com?utm_source=web_application-source&utm_medium=${encodeURIComponent(
              currentPageStore.utmMedium
            )}&utm_campaign=web_application-${encodeURIComponent(selectedRaceStore.race?.name || 'na')}-campaign`"
            target="_blank"
          >
            experirace.com
          </a>
        </i18n-t>
      </v-col>
    </v-row>

    <v-spacer class="ma-3"></v-spacer>

    <v-row no-gutters>
      <v-col class="d-flex justify-center" style="vertical-align: middle">
        <div class="app-footer__experirace">
          Copyright © {{ new Date().getFullYear() }} <strong>ExperiRace Zrt.</strong> {{ t("common.copyright") }}
        </div>
        <language-selector class="ml-5"></language-selector>
      </v-col>
    </v-row>
    <v-row v-if="selectedRaceStore.race?.startAt && currentTimeStore.offset !== 0">
      <v-col class="text-center" style="font-size: 12px">
        <div><strong>Rewinded time:</strong> {{ dayjs(currentTimeStore.time || new Date()).format("YYYY.MM.DD. HH:mm:ss") }}</div>
        <div><strong>Race start:</strong> {{ dayjs(selectedRaceStore.race?.startAt as Date).format("YYYY.MM.DD. HH:mm:ss") }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.app-footer .v-btn {
  text-transform: none;
  font-size: 14px;
  line-height: 20px;
}

.app-footer__discover,
.app-footer__discover:visited,
.app-footer__discover:active {
  color: rgb(var(--v-theme-primary));
  font-weight: 700;
  text-decoration: none;
}

.app-footer__experirace {
  padding-top: 9px;
}
</style>
@/stores/currentPageData