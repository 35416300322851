import { ref, type Ref } from "vue";
import { defineStore } from "pinia";
import { AppBarTitleType } from "#/domain/types";

export const useAppBarStore = defineStore('appBar', () => {
  const titleComponentType: Ref<AppBarTitleType> = ref(AppBarTitleType.SPACER);
  const hideOnScroll = ref(true);

  function $reset() {
    titleComponentType.value = AppBarTitleType.SPACER;
    hideOnScroll.value = true;
  }

  return { titleComponentType, hideOnScroll, $reset };
});