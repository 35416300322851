<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();

import CountryFlag from "vue-country-flag-next";

const route = useRoute();
const router = useRouter();

const languageItems = [
  { id: "en-us", label: "English (United States)", flag: "us" },
  { id: "en-gb", label: "English (United Kingdom)", flag: "gb" },
  { id: "de-de", label: "Deutsch (Deutschland)", flag: "de" },
  { id: "de-at", label: "Deutsch (Österreich)", flag: "at" },
  { id: "hu-hu", label: "Magyar", flag: "hu" },
];

const changeLanguage = (newLanguage: string) => {
  const query = { ...route.query, lang: newLanguage };
  router.push({ query });
};
</script>

<template>
  <div class="language-selector">
    <v-select
      :items="languageItems"
      item-title="label"
      item-value="id"
      :model-value="locale"
      variant="outlined"
      density="compact"
      :hide-details=true
      @update:model-value="changeLanguage($event)"
    >
      <template #item="{ item, props }">
        <v-list-item class="language-selector-item" v-bind="props">
          <template #title>
            <country-flag :country="item.raw.flag" size="small" class="language-selector__list__country-flag" />
            {{ item.raw.label }}
          </template>
        </v-list-item>
      </template>
      <template #selection="{ item }">
          <country-flag :country="item.raw.flag" size="small" class="language-selector__selection__country-flag" />
      </template>
    </v-select>
  </div>
</template>

<style scoped>

.language-selector {
  min-width: 65px;
}
.language-selector__list__country-flag {
  margin-right: -12px !important;

}
</style>
