import "vuetify/styles";
import { createVuetify, type ThemeDefinition } from "vuetify";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { useI18n } from "vue-i18n";
import { i18n } from "./i18n";
import { md1 } from "vuetify/blueprints";
import '@/assets/main.scss'

const experiraceLight: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#F5F5F5',
    surface: '#fff',
    primary: '#F9A329',
    'primary-darken-1': '#2B2F31',
    secondary: '#FDECD3',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    // 4867ea
    // ea4848
    'primary-dark': '#2B2F31',
    'primary-dark2': '#4F5455',
    'primary-light': '#FFFFFF',
    'secondary-dark': '#FDECD3',
    'secondary-light': '#F5F5F5',

    'background-dark': '#E8E8E8',
    'on-primary-light': '#2B2F31',
    'text-dark': '#55585A',
  },
}

export const vuetify = createVuetify({
  locale: {
    adapter: createVueI18nAdapter({ i18n: i18n as any, useI18n }),
  },
  blueprint: md1,
  theme: {
    defaultTheme: 'experiraceLight',
    themes: {
      experiraceLight,
    },
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 5,
      darken: 5,
    },
  }
});

export default vuetify;
