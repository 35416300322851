<script setup lang="ts">
import AppLogo from "@/components/AppLogo.vue";
import { computed } from "vue";
import { AppBarTitleType } from "#/domain/types";
import { logout } from "@/libs/auth";

import BroadcastHeader from "@/components/broadcast/BroadcastHeader.vue";
import BroadcastSimpleHeader from "@/components/broadcast/BroadcastSimpleHeader.vue";
import AppBarNotifications from "@/components/AppBarNotifications.vue";
import { VSpacer } from "vuetify/components";

import { useAppBarStore } from "@/stores/appBar";
const appBarStore = useAppBarStore();

import { useUserStore } from "@/stores/user";
import { useFirebaseAuth } from "vuefire";
const userStore = useUserStore();

const APP_BAR_TITLE_COMPONENTS: Record<AppBarTitleType, any> = {
  [AppBarTitleType.BROADCAST_HEADER]: BroadcastHeader,
  [AppBarTitleType.BROADCAST_SIMPLE_HEADER]: BroadcastSimpleHeader,
  [AppBarTitleType.SPACER]: VSpacer,
};

const titleComponent = computed(() => {
  return APP_BAR_TITLE_COMPONENTS[appBarStore.titleComponentType];
});

const isSpacerTitle = computed(() => appBarStore.titleComponentType === AppBarTitleType.SPACER);

const auth = useFirebaseAuth();

const onLogout = async () => {
  await logout(auth!);
};
</script>

<template>
  <v-app-bar
    color="primary-light"
    elevation="0"
    rounded="0"
    :scroll-behavior="appBarStore.hideOnScroll ? 'hide' : 'default'"
    scroll-threshold="180"
  >
    <v-app-bar-nav-icon density="comfortable" variant="text" icon="mdi-menu" style="visibility: hidden;"></v-app-bar-nav-icon>

    <app-logo :always-show="isSpacerTitle" :centered="isSpacerTitle" style="position: relative; left: -10px; " />

    <component :is="titleComponent" />

    <v-card-actions>
      <app-bar-notifications />

      <v-menu v-if="userStore.isLoaded && userStore.isLoggedIn">
        <template v-slot:activator="{ props }">
          <v-btn density="comfortable" :icon="userStore.isLoggedIn ? 'mdi-account' : 'mdi-login'" v-bind="props"></v-btn>
        </template>

        <v-list density="compact">
          <v-list-item v-if="userStore.isLoggedIn" rounded="0">
            <v-list-item-title class="text-center">
              <div class="px-4 text-subtitle-2 text-primary-dark2">Logged in as</div>
              <div class="px-4 text-body-2">{{ userStore.user?.email }}</div>
              <v-divider class="mt-3"></v-divider>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="!userStore.isLoggedIn"
            rounded="0"
            prepend-icon="mdi-google"
            :active="false"
            :to="{ name: 'login' }"
          >
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="userStore.isLoggedIn"
            rounded="0"
            prepend-icon="mdi-logout"
            :active="false"
            @click="onLogout"
          >
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>
  </v-app-bar>
</template>
