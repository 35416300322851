import { nextTick, isRef, inject } from "vue";
import { createI18n as createI18nOriginal, I18nT } from "vue-i18n";
import type { I18n, I18nOptions, Locale, VueI18n, Composer, I18nMode } from "vue-i18n";
import enUS from "../locales/en-us.json";
import huHU from "../locales/hu-hu.json";
import deDE from "../locales/de-de.json";
export { useI18n, type Locale, type I18nOptions } from "vue-i18n";
import { type VueCookies } from "vue-cookies";
import type { Language, Translation } from "#/domain/types";

const DEFAULT_LOCALE = "en-us";
export const SUPPORTED_LOCALES = ["en-us", "en-gb", "hu-hu", "de-de", "de-at"];

function isComposer(instance: VueI18n | Composer, mode: I18nMode): instance is Composer {
  return mode === "composition" && isRef(instance.locale);
}

const navigatorLanguage = navigator.language?.toLowerCase().replace("-", "_") as Locale;

(I18nT as any).props.scope.default = "global";

export const createI18n = (options: I18nOptions = { locale: DEFAULT_LOCALE }) => {
  const i18n = createI18nOriginal({
    legacy: false,
    messages: {
      "en-us": enUS,
      "en-gb": enUS,
      "hu-hu": huHU,
      "de-de": deDE,
      "de-at": deDE,
    },
    ...options,
  });

  return i18n;
};

export const setI18n = async (i18n: I18n, newLocale?: Locale) => {
  let startLocale = navigatorLanguage;
  if (startLocale === "en") startLocale = DEFAULT_LOCALE;

  let locale = formatLocalToISO(newLocale?.toLowerCase() || getLocaleFromCookie() || startLocale);

  if (!locale) return;

  if (!SUPPORTED_LOCALES.includes(locale)) {
    console.error(`Locale ${locale} not supported, supported locales: ${SUPPORTED_LOCALES.join(", ")}`);
    locale = DEFAULT_LOCALE;
  }

  if (!i18n.global.availableLocales.includes(locale)) {
    await loadLocaleMessages(i18n, locale);
  }

  setI18nLibGlobalLocale(i18n, locale);
  document.querySelector("html")!.setAttribute("lang", locale);
  console.debug("Set locale to", locale);

  setLocaleToCookie(locale);
};

const formatLocalToISO = (locale?: string) => {
  if (!locale) return;

  locale = locale.toLowerCase().replace("_", "-");

  if (locale.indexOf("-") === -1) {
    locale += "-" + locale;
  }

  return locale;
};

const getResourceMessages = (r: any) => r.default || r;

export const loadLocaleMessages = async (i18n: I18n, locale: string) => {
  const messages = await import(/* webpackChunkName: "locale-[request]" */ `../locales/${locale}.json`).then(
    getResourceMessages
  );
  i18n.global.setLocaleMessage(locale, messages);
  return nextTick();
};

const getLocaleFromCookie = () => {
  const $cookies = inject<VueCookies>("$cookies") as VueCookies;
  return $cookies?.get("locale") as Locale;
};

const setLocaleToCookie = (locale: Locale) => {
  const $cookies = inject<VueCookies>("$cookies") as VueCookies;
  $cookies?.set("locale", locale);
};

const setI18nLibGlobalLocale = (i18n: I18n, locale: Locale): void => {
  if (isComposer(i18n.global, i18n.mode)) {
    i18n.global.locale.value = locale;
  } else {
    i18n.global.locale = locale;
  }
};

export const i18n = createI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
});

export function getCurrentLocale(): Language {
  return (i18n.global.locale as any).value;
}

export function getText(text: Translation | undefined | null): string {
  if (!text) return "";

  return text[getCurrentLocale()] || text["en-us"] || "";
}

export function hasText(text: Translation | undefined | null) {
  return getText(text) !== "";
}

export function getTextSkipFallback(text: Translation | undefined | null): string {
  if (!text) return "";

  return text[getCurrentLocale()] || "";
}

export function hasTextSkipFallback(text: Translation | undefined | null) {
  return getTextSkipFallback(text) !== "";
}
