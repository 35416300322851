import { initializeApp } from "firebase/app";
import { firebaseConfig, useFirestoreEmulator } from "@/config";
import { useDocument as useDocumentFS, useCollection as useCollectionFS, type UseDocumentOptions } from "vuefire";
import {
  getFirestore,
  initializeFirestore,
  connectFirestoreEmulator,
  Firestore,
  doc as docFS,
  collection as collectionFS,
  DocumentReference,
  query as queryFS,
  QueryConstraint,
  addDoc as addDocFS,
  setDoc as setDocFS,
  updateDoc as updateDocFS,
  type DocumentData,
  collectionGroup as collectionGroupFS,
  // setLogLevel,
} from "firebase/firestore";
import { getConverter } from "@/firebase/converter";

export { serverTimestamp, onSnapshot } from "firebase/firestore";

export const firebaseApp = initializeApp(firebaseConfig);

// setLogLevel("debug");

export let db: Firestore;

if (useFirestoreEmulator) {
  db = getFirestore();

  if ((db.toJSON() as any).settings.host !== "127.0.0.1:8080") {
    console.log("Connecting to emulator...");
    connectFirestoreEmulator(db, "127.0.0.1", 8080);
    console.log("Firestore connected to emulator...");
  }
} else {
  db = initializeFirestore(firebaseApp, {
    // CACHING WAS DISABLED INTENTIONALLY TO AVOID COLLECTING A LOT OF DOCUMENTS WHAT THE SDK KEEP SYNCHING
    // localCache: persistentLocalCache(/*settings*/ { tabManager: persistentMultipleTabManager() }),
  });
  console.debug("Firestore connected to Cloud server...");
}

export function useDocument<T>(path: string, documentId: string, options?: UseDocumentOptions<T>) {
  return useDocumentFS<T>(doc(path, documentId), options);
}

export function doc<T>(path: string, documentId?: string): DocumentReference {
  if (documentId) {
    return docFS(collectionFS(db, path), documentId).withConverter(getConverter<T>());
  } else {
    return docFS(db, path).withConverter(getConverter<T>());
  }
}

export function collection<T>(path: string) {
  return collectionFS(db, path).withConverter(getConverter<T>());
}

export function collectionGroup<T>(path: string) {
  return collectionGroupFS(db, path).withConverter(getConverter<T>());
}

export function addDoc<T extends DocumentData>(path: string, data: T): Promise<DocumentReference> {
  return addDocFS(collectionFS(db, path).withConverter(getConverter<T>()), data);
}

export function setDoc<T extends DocumentData>(path: string, data: T): Promise<void> {
  return setDocFS(docFS(db, path).withConverter(getConverter<T>()), data);
}

export function updateDoc<T extends DocumentData>(path: string, data: T): Promise<void> {
  return updateDocFS(docFS(db, path).withConverter(getConverter<T>()), data);
}

export function useCollection<T>(path: string, ...queryConstraints: QueryConstraint[]) {
  return useCollectionFS<T>(query<T>(path, ...queryConstraints));
}

export function query<T>(path: string, ...queryConstraints: QueryConstraint[]) {
  return queryFS(collectionFS(db, path), ...queryConstraints).withConverter(getConverter<T>());
}

export function queryCollectionGroup<T>(path: string, ...queryConstraints: QueryConstraint[]) {
  return queryFS(collectionGroupFS(db, path), ...queryConstraints).withConverter(getConverter<T>());
}

export function generateId(): string {
  return docFS(collectionFS(db, "dummy")).id;
}
