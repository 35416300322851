import { computed, ref, type Ref } from "vue";
import { defineStore } from "pinia";

let timer: ReturnType<typeof setTimeout> | null = null;

export type CurrentTimeStore = ReturnType<typeof useCurrentTimeStore>;

export const useCurrentTimeStore = defineStore("currentTime", () => {
  const offsetMs: Ref<number> = ref(0);
  const now: Ref<Date> = ref(new Date());

  const time: Ref<Date> = computed(() => new Date(now.value.getTime() + offsetMs.value));

  if (timer) clearInterval(timer);
  timer = setInterval(() => {
    now.value = new Date();
  }, 1000);

  function $reset() {
    offsetMs.value = 0;
    now.value = new Date();
  }

  return { $reset, time, offset: offsetMs };
});
