<script setup lang="ts">
import { ref, type Ref, watch, onUnmounted, computed } from "vue";
import { useSelectedRaceStore } from "@/stores/selectedRace";
import { useSelectedParticipantStore } from "@/stores/selectedParticipant";
import { useNotificationStore } from "@/stores/notification";
import { NotificationPermissionStatus } from "#/domain/types";
import { subscribeToParticipant } from "@/libs/notification/subscription";
import BlockedSubscription from "./SubscribeToParticipant/BlockedSubscription.vue";
import { isIOS, isPWAiOS, isWebPushSupported } from "@/libs/navigator";
import InstallPwa from "./SubscribeToParticipant/InstallPWA.vue";

import notificationImage from "@/assets/notification.jpg";

const notifications = useNotificationStore();
const selectedParticipantStore = useSelectedParticipantStore();
const selectedRaceStore = useSelectedRaceStore();

import { useI18n } from "vue-i18n";
import IosVersionNotSupported from "@/components/broadcast/SubscribeToParticipant/IosVersionNotSupported.vue";
const { t, locale } = useI18n();

const isDialogOpened: Ref<boolean> = ref(false);
const firsPermissionRequestTimeout: Ref<ReturnType<typeof setTimeout> | null> = ref(null);
const isFirstPermissionRequest: Ref<boolean> = ref(false);
const isSuccessfullySubscribed: Ref<boolean> = ref(false);
const isSubscribing: Ref<boolean> = ref(false);

onUnmounted(() => {
  isDialogOpened.value = false;
});

async function continuouslyTryToSubscribe() {
  await notifications.requestPermission();

  if (notifications.settings.statusOfNotificationPermission === NotificationPermissionStatus.GRANTED) {
    isFirstPermissionRequest.value = false;
    isSubscribing.value = true;

    try {
      await subscribeToParticipant(
        selectedRaceStore.race!.id,
        selectedParticipantStore.participant!.id,
        notifications.settings.token!,
        locale.value
      );
    } catch (e: any) {
      console.error("Problem during subscription to participant", e);
    }

    isSubscribing.value = false;
    isSuccessfullySubscribed.value = true;
    isDialogOpened.value = false;
    return;
  }

  if (isDialogOpened.value) {
    setTimeout(continuouslyTryToSubscribe, 1000);
  }
}

watch(isDialogOpened, async (newValue) => {
  if (!newValue) return;

  if (notifications.settings.statusOfNotificationPermission === NotificationPermissionStatus.DEFAULT) {
    isFirstPermissionRequest.value = true;
    disableFirstPermissionRequestStateAfterSomeTime();
  }

  isSubscribing.value = true;
  await continuouslyTryToSubscribe();
  isSubscribing.value = false;
});

function disableFirstPermissionRequestStateAfterSomeTime() {
  if (firsPermissionRequestTimeout.value) clearTimeout(firsPermissionRequestTimeout.value);

  firsPermissionRequestTimeout.value = setTimeout(() => {
    isFirstPermissionRequest.value = false;
  }, 5000);
}

const isNotificationPermissionInitial = computed(() => {
  return (
    isFirstPermissionRequest.value &&
    notifications.settings.statusOfNotificationPermission !== NotificationPermissionStatus.GRANTED
  );
});

const isNotificationPermissionDenied = computed(() => {
  return notifications.settings.statusOfNotificationPermission === NotificationPermissionStatus.DENIED;
});

const indeterminedState = computed(() => {
  return (
    !isNotificationPermissionInitial.value && !isNotificationPermissionDenied.value && !isSuccessfullySubscribed.value
  );
});

const isLoading = computed(() => {
  return isWebPushSupported() && (isSubscribing.value || indeterminedState.value);
});
</script>

<template>
  <v-dialog v-model="isDialogOpened" activator="parent" max-width="550">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col class="text-h5 pb-0">
            <template v-if="isSuccessfullySubscribed">
              {{ t("pages.broadcastsParticipant.subscribeSuccessfulTitle") }}
            </template>
            <template v-else>
              {{ t("pages.broadcastsParticipant.subscribeTitle") }}
            </template>
          </v-col>
        </v-row>
        <v-row>
          <template v-if="isIOS() && !isWebPushSupported()">
            <v-col>
              <ios-version-not-supported />
            </v-col>
          </template>
          <template v-else-if="isIOS() && !isPWAiOS()">
            <v-col>
              <install-pwa />
            </v-col>
          </template>
          <template v-else-if="isNotificationPermissionInitial">
            <v-col>
              {{ t("pages.broadcastsParticipant.subscribeInitialMessage") }}
              <div class="notification-image mt-5">
                <img :src="notificationImage" class="elevation-3" />
              </div>
            </v-col>
          </template>

          <template v-else-if="isLoading">
            <v-col>
              <i18n-t keypath="pages.broadcastsParticipant.subscribeProgressMessage" tag="span">
                <template v-slot:name>
                  <strong class="text-primary">{{ selectedParticipantStore.participant?.name }}</strong>
                </template>
              </i18n-t>
            </v-col>
          </template>

          <template v-else-if="isNotificationPermissionDenied">
            <v-col>
              <blocked-subscription />
            </v-col>
          </template>
        </v-row>
        <v-row v-if="isLoading">
          <v-col>
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="!isSubscribing" color="primary" block @click="isDialogOpened = false">{{
          t("common.closeDialog")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.notification-image img {
  max-width: 100%;
}
</style>
