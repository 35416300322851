{
  "title": "ExperiRace",
  "pages": {
    "home": {
      "title": "Főoldal",
      "welcome": "Üdv versenyzők!",
      "subtitle": "A lehető legjobb helyen vagy, ha közvetítést keresel!"
    },
    "login": {
      "title": "Belépés",
      "welcome": "Belépés az alkalmazásba",
      "googleLogin": "Belépés Google fiókkal",
      "alreadyLoggedIn": "Köszöntjük az alkalmazásban!"
    },
    "generalBroadcast": {
      "participantBtnTooltip": "A futó személyes közvetítése",
      "followBtn": "Kövesd a kedvenc futód"
    },
    "broadcast": {
      "promote": "Ezt a versenyt közvetítjük, dőlj hátra és légy Te is részese az élménynek!",
      "liveButtonBeforeRace": "A Közvetítés Hamarosan Kezdődik",
      "liveButtonDuringRace": "Nézd az Élő Közvetítést",
      "liveButtonAfterRace": "Nézd meg a Visszajátszást",
      "goToBroadcast": "Tovább a futó közvetítésére",
      "broadcast": "Teljes hozzáférés",
      "broadcasted": "Teljes hozzáférés",
      "track": "Megvásárolható videók",
      "tracked": "Megvásárolható videók",
      "feedTitle": "Kattints ide",
      "feedSubtitle": "És nézd az élő közvetítést",
      "runners": "Regisztrált futók",
      "tableBeforeRace": "Regisztrált versenyzők",
      "tableDuringRace": "A verseny állása",
      "tableAfterRace": "Eredménylista",
      "finishRaceTimeSecBeforeFinish": "Becsült célba érkezés",
      "finishRaceTimeSecAfterFinish": "Célba érkezés",
      "estimatedDistance": "Aktuálisan becsült távolság",
      "lastAppearance": "Utoljára mért pozíció",
      "sort": "Rendezés",
      "bib": {
        "asc": "Rajtszám szerint növekvő",
        "desc": "Rajtszám szerint csökkenő"
      },
      "participant": {
        "asc": "Név A-Z",
        "desc": "Név Z-A"
      },
      "progress": {
        "asc": "Vezetők",
        "desc": "Sereghajtók"
      }
    },
    "broadcastsParticipant": {
      "noMoment": "Még semmit nem rögzítettünk a kért futóhoz :(",
      "raceNotStartedLabel": "A verseny egyelőre nem kezdődött el!",
      "raceNotStartedTime": "Rajt ideje: {time}",
      "raceStartedNoMomentLabel": "A verseny elkezdődött",
      "raceStartedNoMomentSoon": "Hamarosan érkezik a rajt videó...",
      "raceFinishedNoRunnerLabel": "A verseny véget ért!",
      "nextCameraPointWithEta": "A versenyzőt {distance}-nél várjuk, ~{eta}-kor",
      "nextCameraPointAnyTime": "A versenyző már bármelyik pillanatban {distance}-nél lehet",
      "finishTitle": "{0} beért a célba",
      "finishTime1": "Versenyideje {0} volt",
      "finishTime2": ", ami {0}",
      "finishTimeFasterToGoal": " gyorsabb a tervezettnél!",
      "finishTimeSlowerToGoal": " lassabb a tervezettnél!",
      "finishTimeFasterToAvg": " gyorsabb az átlagosnál!",
      "finishTimeSlowerToAvg": " lassabb az átlagosnál!",
      "calendarEventTitle": "{name} versenye",
      "calendarEventDescription": "Kövesd {name} versenyét!\n\nURL: {url}",
      "feedback": "Kérjük {button} hogy jobbá tehessük a szolgáltatást",
      "feedbackButton": "Jelezz vissza",
      "getUpdatesClickHere": "Iratkozz fel a frissítésekre",
      "getUpdatesBarTooltip": "Iratkozz fel, hogy értesítéseket kaphass erről a versenyzőről",
      "unsubscribeClickHere": "Leiratkozás a frissítésekről",
      "unsubscribeBarTooltip": "Leiratkozás a versenyző értesítéseiről",
      "subscribeTitle": "Feliratkozás frissítési értesítésekre",
      "subscribeInitialMessage": "Fogadd el az értesítéseket a böngészőben megjelenő ablakban, így értesülhetsz a versenyzővel kapcsolatos legfrissebb hírekről.",
      "subscribeSuccessfulTitle": "Sikeresen feliratkoztál az értesítésekre",
      "subscribeProgressMessage": "Feliratkozás {name} értesítéseire...",
      "unsubscribeTitle": "Leiratkozás frissítési értesítésekről",
      "unsubscribeProgressMessage": "Leiratkozás {name} értesítéseiről...",
      "unsubscribeConfirm": "Biztosan le szeretnél iratkozni {name} értesítéseiről?",
      "orderPostRaceVideo": "Vásárold meg az összefoglaló videót",
      "orderParticipantBroadcast": "Vásárold meg a versenyző közvetítését",
      "goToPostRaceVideo": "Nézd meg a verseny összefoglaló videót",
      "lapN": "{0}. kör",
      "previewMoment": {
        "bannerTitle": "Futó közvetítés {0}",
        "bannerTitlePreview": "előnézet",
        "title": "Vedd meg {0} videóit",
        "preview": "Ez csupán egy előnézet.",
        "descriptionLine1": "Ha az {quality} szeretnéd látni a videókat, {purchase} a futó közvetítését. Ráadásul egy {postrace} is megajándékozunk a verseny végeztével!",
        "quality": "eredeti minőségben és felbontásban",
        "purchase": "vásárold meg",
        "postrace": "exkluzív összefoglaló videóval",
        "descriptionLine2": "{0} ha többet is szeretnél tudni ezekről.",
        "clickhere": "Kattints ide",
        "order": "Közvetítés megvásárlása"
      }
    },
    "broadcastsParticipantsPostrace": {
      "title": "Verseny videó",
      "smallTitle": "A {0} versenyen futottam",
      "like": "Ha tetszik, kérlek like-old!"
    },
    "translationTest": {
      "title": "Teszt cím"
    },
    "admin": {
      "generalShots": {
        "deleteTitle": "Vágókép törlése",
        "deleteMessage": "Biztosan törölhető a következő vágókép?",
        "noRepeatStart": "Ne ismétlődjön",
        "noRepeatEnd": "percig",
        "participants": "Interjún szereplők",
        "generalDescription": "Egyszer játszott vágókép egy néző közvetítésében",
        "beforeAfterStartDescription": "Folyamatosan ismételt vágókép, ha nincs más amit játszhatnánk",
        "beforeStart": "a rajt előtt",
        "afterStart": "a rajt után",
        "cameraPointIntro": "Felvezető a",
        "cameraPointIntroOn": "kamera ponton, a",
        "cameraPointIntroTrack": "futamon",
        "missingCameraPoint": "Hiányzó kamera pont",
        "missingTrack": "Hiányzó futam",
        "missingInterviewee": "Hiányzó interjúalany",
        "interviewDescription": "Interjú a következő versenyzőkkel:",
        "repeatableDescription": "Vágókép, ami",
        "repeatableDescriptionEnd": "percenként ismétlődhet a közvetítésben",
        "addNewShot": "Új vágókép feltöltése",
        "preparingShot": "Feldolgozás alatt...",
        "text": "Felirat hozzáadása a vágóképhez"
      },
      "cameraPoints": {
        "pageTitle": "Kamera pontok",
        "pageSubtitle": "Beállítás & Monitoring",
        "selectTracks": "Válassz távot",
        "zoomToTracks": "A távok mutatása",
        "showReceptionMetrics": "Hálózati metrikák mutatása",
        "showMoments": "Videók mutatása",
        "showMomentCircles": "Videók sugarának mutatása",
        "changeLayers": "Réteg váltása",
        "startPoints": "Táv(ok) kezdete",
        "finishPoints": "Táv(ok) vége",
        "name": "Név",
        "rank": "Sorrend",
        "coordinates": "Koordináták",
        "latitude": "Szélesség",
        "longitude": "Hosszúság",
        "altitude": "Magasság",
        "track": "Táv",
        "positionOnTracks": "Elhelyezkedés a táv(ok)on",
        "claimedBy": "Belépve",
        "lastClaimBy": "Belépve",
        "isStart": "Rajt",
        "isFinish": "Cél",
        "distance": "Távolság",
        "elevationGain": "Szintemelkedés",
        "plannedTime": "Tervezett idő",
        "tracks": "Futamok",
        "averageUploadSpeed": "Átlagos feltöltési sebesség",
        "networkSpeedMeasurement": "Hálózat sebesség",
        "videoLengthUploadDurationRatio": "Videó hossza és feltöltési idő aránya",
        "videoLengthUploadDurationRealtime": "Valós idejű feltöltés",
        "videoLengthUploadDurationSlow": "Nem valós idejű feltöltés",
        "moment": "Videó",
        "momentId": "Moment ID",
        "shotId": "Shot ID",
        "trackId": "Track ID",
        "recordingFinishedAt": "Felvétel vége",
        "uploadStartedAt": "Feltöltés kezdete",
        "cameraPointOwnerName": "Videós neve",
        "title": {
          "cameraPoints": "Kamera pontok",
          "videographers": "Videósok"
        },
        "videographers": {
          "cameraPointNames": "Kamera",
          "name": "Név",
          "online": "Aktív",
          "deviceName": "Eszköz",
          "battery": "Akkumulátor",
          "queueSize": "Feltöltendő Videók",
          "diskSize": "Szabad Tárhely",
          "lastCameraPointLoginAt": "Utolsó bejelentkezés"
        }
      }
    },
    "permissionDenied": {
      "title": "Hozzáférés megtagadva",
      "message": "Sajnáljuk, de nincs jogosultságod az oldal megtekintéséhez."
    }
  },
  "broadcast": {
    "raceStartSoon": "A verseny hamarosan kezdődik",
    "startCameraPoint": "Rajt",
    "finishCameraPoint": "Cél"
  },
  "notifications": {
    "blockedMessage": "Úgy tűnik, hogy korábban letiltottad az értesítések megjelenítését ezen a weboldalon.",
    "blockedToEnable": "Ha frissítési értesítéseket szeretnél kapni a futóról, engedélyezd az értesítéseket:",
    "blockedLastStep": "Lehetséges, hogy újra kell töltened az oldalt és újra feliratkozni",
    "blockedChrome": {
      "step1": "Kattints a lakat ikonra a böngésző címsávjának bal oldalán",
      "step2": "Engedélyezd az értesítéseket"
    },
    "blockedSafari": {
      "step1": "Nyisd meg a böngésző beállításait a menüsávon",
      "step2": "Menj a Webhelyek menüpontra",
      "step3": "Válaszd ki az Értesítések menüpontot a bal oldalon, majd engedélyezd az értesítéseket az app.experirace.com weboldalhoz"
    },
    "blockedEdge": {
      "step1": "Kattints a lakat ikonra a böngésző címsávja bal oldalán",
      "step2": "Engedélyezd az értesítéseket"
    },
    "blockedAndroid": {
      "step1": "Kattints a harang vagy beállítások ikonra a böngésző címsávjának bal oldalán",
      "step2": "Kattints az Értesítések menüpontra",
      "step3": "Engedélyezd az értesítéseket"
    },
    "blockedIOS": {
      "step1": "Nyisd meg a Beállítások alkalmazást",
      "step2": "Keresd meg az ExperiRace alkalmazás beállításait",
      "step3": "Engedélyezd az értesítéseket"
    },
    "blockedDefault": {
      "step1": "Nyisd meg az aktuális oldalhoz tartozó beállításokat a böngésző címsávja mellett (általában egy lakat vagy fogaskerék ikon), vagy nyisd meg az alkalmazás beállításait",
      "step2": "Keresd meg az Értesítések beállításait",
      "step3": "Engedélyezd az értesítéseket a weboldalhoz"
    },
    "pwaInstall": {
      "message": "iOS eszközökön csak úgy kaphat értesítéseket, ha hozzáadja az ExperiRace weboldalt a főképernyőjéhez.",
      "toEnable": "Az értesítések beállításához kövesse a következő lépéseket:",
      "step1": "A böngésző alján, a címsor alatt kattintson a Megosztás gombra",
      "step2": "Válaszd a 'Főképernyőhöz Adás' opciót, majd kattints a Hozzáadásra",
      "step3": "Keresd meg az ExperiRace ikont az alkalmazásaid között",
      "step4": "Nyisd meg az alkalmazást és iratkozz fel a versenyző értesítéseire. Mostantól használd az ExperiRace-t a főképernyőről a weboldal helyett.",
      "versionErrorMessage": "Az értesítések csak az iOS 16.4 vagy újabb verzióját futtató eszközökön támogatottak.",
      "upgradeInstructions": "Kérjük, kövesse az Apple támogatási oldalán található részletes utasításokat iOS eszköze frissítéséhez. Az útmutatóhoz kattintson ide: {link}",
      "upgradeInstructionsUrl": "https://support.apple.com/hu-hu/HT204204"
    }
  },
  "feed": {
    "goTo": "{click} és nézd az {live} közvetítést",
    "goToClickHere": "Kattints ide",
    "goToLive": "élő",
    "broadcastOff": "Egy kis szünetet tartunk, de dolgozunk az általános közvetítésen...",
    "checkOutParticipants": "Addig is nézd meg a versenyzők élő közvetítését"
  },
  "navigation": {
    "home": "Főoldal",
    "about": "Rólunk",
    "faq": "GYIK",
    "addRace": "Saját verseny",
    "races": "Versenyek"
  },
  "footer": {
    "discover": "Fedezd fel a többi közvetítésünket is az oldalunkon {0}"
  },
  "videographer": {
    "os": "OS",
    "app": "App",
    "battery": "Akku",
    "batterySaveMode": "Akku kímélő üzemmód",
    "batterySaveModeIsActive": "aktív",
    "storage": "Tárhely",
    "queue": "Feltöltés",
    "storageFree": "szabad",
    "storageTotal": "teljes",
    "queueVideos": "{0} videó feltöltésre vár",
    "network": "Hálózat",
    "networkLast1Min": "Elmúlt 1 perc",
    "networkLast5Mins": "Elmúlt 5 perc",
    "networkSpeed": "Sebesség",
    "networkUploaded": "Adat",
    "lastDataFrom": "Utolsó adat"
  },
  "map": {
    "outdoors": "Túra",
    "satellite": "Műhold",
    "streets": "Utcák",
    "light": "Világos",
    "dark": "Sötét",
    "satelliteStreets": "Műhold & Utcák",
    "standard": "Standard"
  },
  "common": {
    "back": "Vissza",
    "unsubscribe": "Leiratkozás",
    "closeDialog": "Ablak bezárása",
    "clickHere": "Kattintson ide",
    "broadcastedOnly": "Csak közvetített",
    "filterByType": "Szűrés típus alapján",
    "broadcasted": "Közvetítésben",
    "uploadedAt": "Feltöltve: {0}",
    "closeEditor": "Szerkesztés bezárása",
    "progress": "Táv",
    "type": "Típus",
    "delete": "Törlés",
    "clone": "Klónozás",
    "edit": "Szerkeszt",
    "cancel": "Mégsem",
    "bib": "RSz",
    "cameraPoint": "Kamera pont",
    "name": "Név",
    "racer": "Futó",
    "track": "Futam",
    "broadcast": "Közvetítés",
    "language": "Nyelvek",
    "loading": "Betöltés...",
    "noRace": "Nem találtuk meg a kért versenyt",
    "noRunner": "Nem találtuk meg a futót",
    "noVideoUploaded": "Egyelőre nincs videó feltöltve :(",
    "download": "Letöltés",
    "raceTime": "Versenyidő",
    "goalTime": "Cél",
    "distance": "Távolság",
    "raceOver": "Verseny vége",
    "raceNotYetStarted": "A verseny még nem kezdődött el",
    "lap": "Kör",
    "startCamera": "A rajt pillanata",
    "finishCamera": "A befutó",
    "finishTimes": "Versenyidő",
    "finished": "Célba ért",
    "share": "Megosztás",
    "search": "Keresés",
    "comments": "{numberOfComments} hozzászólás",
    "copyright": "Minden jog fenntartva.",
    "backToHome": "Vissza a főoldalra",
    "na": "N/A",
    "naDetailed": "Nincs információnk a futóról",
    "noFinishTime": "Hiányzó célidő",
    "noFinishTimeTooltip": "Nem láttuk a futó célba érkezését",
    "metrics": {
      "kilometer": "km",
      "meter": "m",
      "hm": "ó:p"
    },
    "generalShotTypes": {
      "general": "Általános",
      "cameraPointIntro": "Kamera pont felvezető",
      "beforeStart": "Rajt előtti háttér",
      "afterStart": "Rajt utáni háttér",
      "interview": "Interjú",
      "repeatable": "Ismétlődő"
    },
    "languages": {
      "en-gb": "Angol (brit)",
      "en-us": "Angol (amerikai)",
      "de-de": "Német",
      "hu-hu": "Magyar"
    },
    "ordinals": {
      "one": "{0}.",
      "two": "{0}.",
      "few": "{0}.",
      "other": "{0}."
    }
  },
  "test": {
    "simple": "tesztelés",
    "params": "tesztelés {param1} {param2}",
    "directiveInterpolation": "tesztelés {0} tesztelés"
  },
  "$vuetify": {
    "badge": "Badge",
    "open": "Open",
    "close": "Close",
    "dataIterator": {
      "noResultsText": "No matching records found",
      "loadingText": "Loading items..."
    },
    "dataTable": {
      "itemsPerPageText": "Rows per page:",
      "ariaLabel": {
        "sortDescending": "Sorted descending.",
        "sortAscending": "Sorted ascending.",
        "sortNone": "Not sorted.",
        "activateNone": "Activate to remove sorting.",
        "activateDescending": "Activate to sort descending.",
        "activateAscending": "Activate to sort ascending."
      },
      "sortBy": "Sort by"
    },
    "dataFooter": {
      "itemsPerPageText": "Oldalanként:",
      "itemsPerPageAll": "All",
      "nextPage": "Next page",
      "prevPage": "Previous page",
      "firstPage": "First page",
      "lastPage": "Last page",
      "pageText": "{0}-{1} a(z) {2} elemből"
    },
    "dateRangeInput": {
      "divider": "to"
    },
    "datePicker": {
      "ok": "OK",
      "cancel": "Cancel",
      "range": {
        "title": "Select dates",
        "header": "Enter dates"
      },
      "title": "Select date",
      "header": "Enter date",
      "input": {
        "placeholder": "Enter date"
      }
    },
    "noDataText": "No data available",
    "carousel": {
      "prev": "Previous visual",
      "next": "Next visual",
      "ariaLabel": {
        "delimiter": "Carousel slide {0} of {1}"
      }
    },
    "calendar": {
      "moreEvents": "{0} more"
    },
    "input": {
      "clear": "Clear {0}",
      "prependAction": "{0} prepended action",
      "appendAction": "{0} appended action",
      "otp": "Please enter OTP character {0}"
    },
    "fileInput": {
      "counter": "{0} files",
      "counterSize": "{0} files ({1} in total)"
    },
    "timePicker": {
      "am": "AM",
      "pm": "PM"
    },
    "pagination": {
      "ariaLabel": {
        "root": "Pagination Navigation",
        "next": "Next page",
        "previous": "Previous page",
        "page": "Go to page {0}",
        "currentPage": "Page {0}, Current page",
        "first": "First page",
        "last": "Last page"
      }
    },
    "stepper": {
      "next": "Next",
      "prev": "Previous"
    },
    "rating": {
      "ariaLabel": {
        "item": "Rating {0} of {1}"
      }
    },
    "loading": "Betöltés...",
    "infiniteScroll": {
      "loadMore": "Load more",
      "empty": "No more"
    }
  },
  "webshop": {
    "productUrl": "https://experirace.com/hu/products/{productHandle}",
    "cartUrl": "https://experirace.com/hu/cart/add?id={productVariantId}&quantity=1&properties[Bib%20number]={bibNumber}"
  },
  "10": "10",
  "25": "25",
  "50": "50",
  "100": "100"
}
