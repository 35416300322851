import * as semver from "semver";

const navigator = window.navigator;

export function isIOS() {
  return (
    /iPhone|iPad|iPod/i.test(navigator.userAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
}

export function getIOSVersion(): string | undefined {
  if (!isIOS()) {
    return;
  }

  const osVersionMatches = navigator.userAgent.match(/OS (\d+(_\d+(_\d+)?)?)/i)

  if (!osVersionMatches || !osVersionMatches[1]) return;

  return osVersionMatches[1].split("_").join(".");
}

export function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

export function isMobile() {
  return isIOS() || isAndroid();
}

export function isDesktop() {
  return !isMobile();
}

export function isSafari() {
  return /Safari/i.test(navigator.userAgent);
}

export function isDesktopChrome() {
  return /Chrome/i.test(navigator.userAgent) && !isMobile();
}

export function isDesktopSafari() {
  return /Safari/i.test(navigator.userAgent) && !isMobile();
}

export function isDesktopEdge() {
  return /Edge|Edg/i.test(navigator.userAgent) && !isMobile();
}

export function isMobileChrome() {
  return /Chrome/i.test(navigator.userAgent) && isMobile();
}

export function isMobileSafari() {
  return isSafari() && isMobile();
}

export function isPWAiOS() {
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
  return isStandalone || (isIOS() && !isSafari());
}

export function isWebPushSupported() {
  if (!isIOS()) return true;

  const minSupportedVersion = semver.coerce("16.4");

  const versionNumber = getIOSVersion();
  const versionOfDevice = semver.coerce(versionNumber);

  if (!versionOfDevice) return false;

  return semver.gte(versionOfDevice, minSupportedVersion!);
}
