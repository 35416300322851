<script setup lang="ts">
import { defineProps } from "vue";
import { useSelectedRaceStore } from "@/stores/selectedRace";

const props = defineProps<{
  alwaysShow?: boolean;
  centered?: boolean;
}>();

const selectedRaceStore = useSelectedRaceStore();
</script>

<template>
  <div class="ml-2 d-flex" :class="{ 'justify-center': props.centered, 'w-100': props.centered }">
    <v-btn position="absolute" :active="false" rounded="5" height="24"
      :href="`https://experirace.com/collections/frontpage?utm_source=web_application-source&utm_medium=web_application-appbar&utm_campaign=web_application-${encodeURIComponent(selectedRaceStore.race?.name || 'na')}-campaign`"
      class="logo text-h5 font-racing px-1 text-sm-h5 ml-2"
      :class="{ 'hidden-xs': !props.alwaysShow, 'font-xlarge': props.centered }">
      Experi<span class="text-primary">Race</span>
    </v-btn>
  </div>
</template>

<style scoped>
.logo {
  top: 50%;
  transform: translateY(-50%);
}
</style>
