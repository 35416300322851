<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();

</script>

<template>
  <div>
    {{ t("notifications.pwaInstall.versionErrorMessage") }}

    <div class="notification-steps mt-3">
      <i18n-t keypath="notifications.pwaInstall.upgradeInstructions" tag="div">
        <template v-slot:link>
          <a :href="t('notifications.pwaInstall.upgradeInstructionsUrl')" target="_blank">{{ t('notifications.pwaInstall.upgradeInstructionsUrl') }}</a>
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<style scoped>
.notification-steps img {
  max-width: 100%;
}
</style>
