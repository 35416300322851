<script setup lang="ts">
import SafariStep1Img from "@/assets/blocked_guide_safari1.jpg";
import SafariStep2Img from "@/assets/blocked_guide_safari2.jpg";
import SafariStep3Img from "@/assets/blocked_guide_safari3.jpg";

import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<template>
    {{ t("notifications.blockedMessage") }}

    <div class="notification-steps mt-3">
        <strong>{{ t("notifications.blockedToEnable") }}</strong>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                1. {{ t("notifications.blockedSafari.step1") }}
            </div>
            <img class="elevation-3" :src="SafariStep1Img" />
        </div>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                2. {{ t("notifications.blockedSafari.step2") }}
            </div>
            <img class="elevation-3" :src="SafariStep2Img" />
        </div>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                3. {{ t("notifications.blockedSafari.step3") }}
            </div>
            <img class="elevation-3" :src="SafariStep3Img" />
        </div>
        <div class="mt-3">
            <div class="bg-blue pa-2 font-weight-bold">
                4. {{ t("notifications.blockedLastStep") }}
            </div>
        </div>
    </div>

</template>

<style scoped>
.notification-steps img {
  max-width: 100%;
}
</style>
